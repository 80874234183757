#coolestTextArea{
	border: none;
	padding: 5px 10px;
	width: 100%;
}
.coolest-post-btn{
	background-color: #032F3C;
	padding: 7px 27px 7px 27px;
	font-weight: 500;
	font-size: 18.81px;
	padding-left: 1.2em;
	padding-right: 1.2em;
	margin-left: 6px;
	margin-right: 6px;
}
.coolest-post-btn:hover{
	background-color: #032F3C;
}
.textArea-body{
	
	padding: 30px;
}
.coolest-project-question{
	
	/* width: 90%; */
	margin: auto;

}


.heading_coolest{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600 !important;
font-size: 16px !important;
}