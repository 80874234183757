.header{
  background-color: #032F3C !important;
}
.new_auth_navbar_top{
    display: grid;
    grid-template-columns: 6%  1fr 60% 6%;
    grid-gap:8px;
    align-items: center;
}

.new_left_space_logo{
    grid-column: 1 / 2;
    grid-row: 1;
    /* background-color: rgb(62, 238, 53);    */
}
.new_top_header_logo_search{
    grid-column: 2 / 3;
    grid-row: 1;
    /* background-color: rgb(232, 53, 238);    */
}
.new_top_header_home_link{
    grid-column: 3 / 4;
    grid-row: 1;
    /* background-color: rgb(53, 176, 238);    */
}
.new_right_space_menu{
    grid-column: 4 / 5;
    grid-row: 1;
    /* background-color: rgb(53, 238, 72);    */
}
 
.ask_edit_btn{
  border: 0.2px solid gray;
  border-radius: 25px;
  text-align: center;
  color: #000;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #FFFFFF;
}

.profile_user_toggle{
  cursor: pointer;
}
.profile_image_user{
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  border-radius: 25px;
}
.prfl_down_arrow{
  width: 11px !important;
  height: 9px !important;
  padding-left: 5px;
}
.prfl_image_left_bar{
  width: 43px ;
  height: 43px ;
  border-radius: 52px;
}
.hello_left_bar{
  color: #22A72F;
  font-weight: 300;
  font-size: 12px;
}
.user_name_left_bar{
  font-weight: 500;
  font-size: 16px;
  color: #181818;
  cursor: pointer;
  text-transform: capitalize;
}

.activity_left_request{
  font-size: 12px;
  border-radius: 25px;
  padding: 5px 12px;
  width: fit-content;
  background: #FFFFFF;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
}

.dot_activity_left{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  

}

.text_acitivity_left{

  color: #000000 !important;
  font-size: 14px;
  font-weight: 500;
}

.mark_as_read_dot_style_green{
   
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #04ca35;
 
}


/* .has_search{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF !important;
    width: 100%;
    position: relative;
    margin-left: 26px;
}

.form_control_feedback {
    position: absolute;
    left: 11px;
    z-index: 2;
    display: block;
    text-align: center;
    cursor: pointer;
  }
 .form_control_feedback:before {
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    display: block;
  }
  .form_control_input {
    padding: 11px 5px 9px 37px;
    border-bottom: 0;
    border-radius: 5px;
    height: auto;
    width: 95%;
    background-color: #f7f7f7;
  }
  .form_control_input::placeholder,
  .form_control_input,
  .form_control_input:focus {
    font-size: 0.9rem;
    color: #cbcdd0;
  } */


