

/* ===== Start responsive mobile screen ============ */

@media (max-width:425px) {
    .public_forum_details_style{
        display: grid;
        grid-template-columns: 1% 1fr 1%;
        /* align-items: center; */
    }
    
    .public_forum_left_space{
        grid-column: 1 / 2;
        grid-row: 1; 
    }
    .public_forum_card_page{
        grid-column: 2 / 3;
        grid-row: 1;  
    }
    .public_forum_right_space{
        grid-column: 3 / 4;
        grid-row: 1; 
    }
    
    .row_style_dflexing{
        display: grid;
        grid-template-columns:  1fr;
    }
    
    .mainBoxStyle{
        grid-column: 1 / 2;
        grid-row: 1;
        margin-top: 40px !important;
        /* padding-left: -6px !important;
        padding-right: -6px !important; */
    }


    .public_forum_right_ads,
    .public_forum_left_ads,
    .left_sidebar_space,
    .right_sidebar_space,
    .sidebar_final_right,
    .sidebar_final_select{
        display: none;
    }
    .row_style_top_header_auth{
        display: grid;
        grid-template-columns: 1% 1fr 10%;
    }
    
    .left_space_logo {
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    .top_header_logo_search{
        grid-column: 2 / 3;
        grid-row: 1;  
        /* background-color: #0077b5; */
    }
    .top_header_home_link{
        grid-column: 3 / 4;
        grid-row: 1;  
    }
    .right_space_menu{
        display: none;
    }
    .saphal_logo_top{
        height: 25px;
        margin: 6px;
    }
    /* .has-search{
        display: none !important;
    } */
    .page-search , .has-search, .hide_mobile_view{
        display: none !important;
    }

    .profile_user_mobile{
        cursor: pointer;
        display: block;
    }
    .mobile_search_box_stlye, .login_signup_mobile_view{
        display: block;
    }
    .login_signup_mobile_view_top{
        display: flex;
        align-items: center;
        justify-content: end;
        /* height: 20%; */
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
    
    .login_signup_mobile_view_top .login_signup_btn{
        border-radius: 5px;
        width: fit-content;
        background-color: #015b91;
        position: relative;
        padding: 8px 15px !important;
    }
    .bottom_top_navbar_link{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-left: 45px !important;
        height: 60px;
        box-shadow: 0 -5px 5px -5px #333;
    }
    .bottom_top_navbar_link .mobile_nav_bottom {
        display: none !important;
    }

    .my_career_activity_style{
        display: grid;
        grid-template-columns: 16% 1fr 6%;
        grid-gap: 5px;
        padding:  10px 26px !important;
        text-align: start;
        /* align-items: center !important; */
        /* justify-content: space-between; */
    }
    .qcard_head{
        font-size: 1.0em;
    }
    .qcard_experience{
        font-size: 0.8em;
    }
    /* .qcard_date{
        font-size: 0.8em;
    } */
    .qcard_edit, .qcard_delete{
        height: 18px;
    }
    .qcard_title{
        font-size: 1.0em;
        font-weight: 600 !important;
    }
    .qcard_description{
        font-size: 0.9em;
    }
    .qcard_comment{
        font-size: 0.8em;
    }
    .qcard_link{
        font-size: 0.8em;
    }
    /* .qcard_read{
        font-size: 0.85em;
    } */

    /* .adding_comment_box{
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-bottom: 0.2rem !important;
        margin-left: -16px !important;
        margin-right: -12px !important;
        padding-left: 36px !important;
    } */
    .add_comm_profile{
        top:6px !important;
        width: 35px !important;
        height: 35px !important;
    }
    .qcard_add_comment{
        font-size: 1em;
    }

    .mobile_show_activity{
        display: block;
    }
    .mobile_student_activity, .nav_links_manage_request, .tagged_div_style{
        display: none;
    }
    .activity_question{
        font-size: 0.6em !important;
    }

    .proleStuImg{
        width: 80px;
        height: 80px;
    }
    .uprfl_activity_logo{
        display: flex;
        align-items: start;
        justify-content: flex-start !important;
    }
    .uprfl_head{
        font-size: 1em;
    }
    .floatrightText{
        margin-left: 2.5rem !important;
    }
    .CollegeDetails{
        display: grid;
        grid-template-columns: 1fr;
    }
    .mentorss{
        display: none !important;
    }
    .mobile_sidebar_scroll{
        /*height: fit-content;
        overflow-y: scroll;*/
    }
    /* .wallet_card_stlye{
        padding-bottom: 7rem !important;
        margin-bottom: 7rem !important;
    } */
    .mobile_sidebar_scroll::-webkit-scrollbar-track
    {
    	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    	background-color: #F5F5F5;
    }
    
    .mobile_sidebar_scroll::-webkit-scrollbar
    {
    	width: 5px;
    	background-color: #F5F5F5;
    }
    
    .mobile_sidebar_scroll::-webkit-scrollbar-thumb
    {
    	background-color: #000000;
    	border: 2px solid #555555;
    }
    #afterLoginScreen .offcanvas-collapse-mobile.open{
        visibility: visible;
    }

    .search-container {
        position: relative;
        /* display: inline-block; */
        /* margin: 4px 2px; */
        height: 50px;
        width: 40px;
        /* vertical-align: bottom; */
        /* padding-right: 32px; */
        display: flex;
        align-items: center;
    }

    
    .searchbutton {
        position: absolute;
        font-size: 16px;
        width: 100%;
        height: 78%;
        margin-bottom: 0;
        /* padding-top: 1px;
        padding-bottom: 1px; */
        /* background-color: #0077b5 !important; */
    }
    
    .search:focus + .searchbutton {
        transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        background-color: #F7F7F7;
        border-radius: 3px;
        color: black;

    }
    
    .search {
        position: absolute;
        left: 40px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
        background-color: #F7F7F7;
        outline: none;
        border: none;
        padding: 0;
        width: 0;
        height: 100%;
        z-index: 10;
        border-radius: 3px;
        transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
    }
    
    .search:focus {
        width: 240px; /* Bar width+1px */
        height: 80%;
        /* padding: 0 16px 0 0; */
    }
    .expandright {
        left: auto;
        right: 36px; /* Button width-1px */
    }
    
    .expandright:focus {
        padding: 0 0 0 16px;
    }

    .search-container .search::placeholder {
        color: rgb(197, 192, 192);
        font-size: 0.9rem;
        font-weight: 520;
      }


      .login_signup_mobile_view_top{
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    /* .mobile_saphal_logo{
        width: 65px;
        height: 30px;
    } */
    .login_signup_mobile_view_top .login_signup_btn{
        border-radius: 5px;
        width: fit-content;
        background-color: #015b91;
        position: relative;
        font-size: 1.0rem;
        font-weight: 600;
        padding: 5px 15px !important;
        align-items: center;
    }
    .before_login_navbar{
        display: grid;
        grid-template-columns: 1%  1fr 55% 1%;
        grid-gap:8px;
        align-items: center;
        background-color: hsl(222, 56%, 96%);
    }
    
    .left_space_navbar{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    .logo_search_navbar{
        grid-column: 2 / 3;
        grid-row: 1;
    }
    .login_signup_navbar{
        grid-column: 3 / 4;
        grid-row: 1;
          
    }
    .right_space_navbar{
        grid-column: 4 / 5;
        grid-row: 1; 
    }
    .notifi-Switch{
        display: none;
    }

    #afterLoginScreen .noti-filter img {
        background-color: #fff;
        padding: 5px 6px;
        width: 28px;
        height: 28px;
        object-fit: scale-down;
        border-radius: 5px;
    }
    
    .coming-image-job{
        width: 100%;
    }
    .coming_soon_job{
        font-size: 22px;
        /* line-height: 54px; */
        font-weight: 500;
        color: #1A6C9D;
    }
    .coming_soon_job .soon_style{
        font-size: 22px;
        /* line-height: 54px; */
        font-weight: 600;
        color: #22A72F;
    }

    .divtext {
        border: ridge 2px;
        padding: 8px 20px 5px 20px !important;
        width: 100% !important ;
        min-height: 2.8em;
        overflow: auto;
    }
    
    .pre-wrap{
        white-space: pre-wrap;
     }
     .pre-line{
        white-space: pre-line;
     }
    
     .inline_box_block{
        display:inline-block
        
    }
}


/* ===== End responsive mobile screen ============ */