.Oppor_card_para_p {
  margin-bottom: 4px;
} 

.oppo_desc_input {
  padding-left: 12px;
  padding-top: 4px;
}

.oppo_desc_input::placeholder {
  /*padding-left: 12px;
  padding-top: 4px;*/
}
.ask_post_oppor_question_input_white {
  color: #959595;
} 

.text_head_time_line_new {
  font-size : "18px!important" ;
}

.ask_post_oppor_question_input_black {
    width: 97%;
    background: #ffffff;
    border-radius: 4.77566px;
    color: black;
}

/*Latest homepage css start*/
.latest-homepage-div {
  background: #000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-style: normal;

}

.homepage-container {
  background-color: #00002E;
  width: 90%;
  height: 85%;
}

.latest-homepage-div .homepage-content {
  padding: 5vh 0vh;
  display: inline-block;
  width: 55%;
  height: 100%;
}
.latest-homepage-div .logo {
  text-align: center;
}

.latest-homepage-div .logo img{
  width: 136px;
  height: 56px;
}

.latest-homepage-div .logo-bottom-text {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-top: 11px;
}
.latest-homepage-div .evolving_to_serve_you_better {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
}

.latest-homepage-div .homepage_desc {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  /*background-color: yellow;*/
  padding: 0px 50px;

}

.latest-homepage-div .homepage_thank_text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.latest-homepage-div .homepage_reach_us {
  color: #099D18 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}
/*Latest homepage css end*/

/*.ask_post_oppor_question_input_white option {
  color: black;
}*/
.ask_post_oppor_question_input_white option[selected="selected"]  {
  color: black;
}

.ask_post_oppor_question_input_white.valid {
  color: black; /* Color when a valid option is selected */
}

.qcard_experience_font {
  font-size: 10px!important;
}

.qcard_head_font {
  font-size: 14px!important;
}
.pl-5 {
  padding-left: 5rem;
}

.pl-rm-4 {
  padding-left: 3rem;
}

#pillc img {
  margin-right: 4px;
}

input.rbt-input-main.form-control.rbt-input::placeholder{
	color : #74746c!important
}

.border-1px-white {
  border: 1px solid white;
}

.border-2px-white {
  border: 2px solid white;
}

.font-size-14{
  font-size: 14px;
  line-height: 12px;
}
.text_para5 {
  font-size: 14px!important;
  font-weight: 400;
}

.rest-of-height {
  flex-grow: 1;
  margin: 0px;
  padding: 0px;
  height: 85vh;
  margin-top: 90px!important;
}
.mobile-chat-back-btn-div {
  display: none!important;
}
.side_head_quick_new {
  font-size: 16px;
  font-weight: 500;
}
.typeahead-custom a {
  font-size: 12px!important;
  /*border: 0.975216px solid rgba(0, 0, 0, 0.1);*/
  color: #959595!important;
  line-height: 12px!important;
  padding: 4px 5px;
}

.step-back-arrow{
  font-size: 21px;
  font-weight: 500;
  cursor: pointer;
}

.typeahead-custom a:hover {
  background: #2789eb;
  color: white!important;
}
.typeahead-custom .dropdown-menu {
  /*color: yellow!important;*/
  border-radius: 4.77566px!important;
  background: white!important;
}

.typeahead-custom {
  position: relative;
  /*display: inline-block;*/
}

/* Style the arrow inside the select */
.typeahead-custom::after {
  content: '\f078'; /* Font Awesome Unicode for the arrow icon */
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #959595;
  font-size: 10px;
}

/* Style the actual text input */
.typeahead-custom input[type="text"] {
  padding-right: 25px; /* Add padding to make space for the arrow */
}

.user_active_color {
  color: white!important;
}

.user_name_active_border {
  border: 2px solid white;
}

.user_deactive_color {
  color: #c4c1c1!important;
}

.user_name_deactive_border {
  border: 2px solid #c4c1c1;
}

.new_button_color{
  background-color: #032f3c!important;
}

#afterLoginScreen  a {
  margin: 0px!important;
}
.visibility-hidden {
  visibility: hidden;
}
.discover_no_data_found a {
  color: #2ea1da;
  font-weight: 600;
  text-decoration: underline;
}
.add-certification-skills-div .rbt-input-multi{
  height: auto!important;
  min-height: 50px;
}

.add-certification-skills-div .rbt-input-multi input{
  padding-top: 6px!important;
}

.add-certification-skills-div .dropdown-menu{
  transform: translate3d(0px, 50px, 0px)!important;
}

.add-certification-skills-div .rbt-input-wrapper{
  height: auto!important;
}

.edit_skill_option_name {
  font-size: 12px;
  color: rgba(20, 20, 20, 0.70);
  font-weight: 400;
  /*margin-right: 20px;*/
}
.edit_skill_option_name_ {
  margin-right: 0px;
  font-size: 12px;
}
.empty_circle{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  border: 1px solid gray;
}
.fill_circle{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  border: 1px solid gray;
}
.fill_circle_span {
  display: block;
  width: 13px;
  height: 13px;
  background: black;
  border-radius: 50%;
  margin: 5px;
}
.opportunity_expiry_btn{  
  border-radius: 5px;
  border: 1px solid #D4D4D4;
  background: #EBEBEB;
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /*line-height: 21px; */
}

.opportunity_expiry_btn span {
  padding: 6px;
}
.opportunity_expiry_btn .time_img {
  height: 24px;
}

.people_also_view_title {
  font-family: Poppins!important;
  font-size: 18px!important;
  font-style: normal;
  font-weight: 600!important;
} 
.people_also_view_passage {
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 150%;
}

.comme-text-n{
  height: 100px;
  border-radius: 8px;
  border: 1px solid #979797;
}

.comme-text-n[contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  color: #A5A5A5;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 2px;
}

.comment-submit-btn-div {
  height: 100px;
  position: relative;
}

.desc-text-placeholder{
  font-size: 12px;
  font-weight: 400;
  color: #A5A5A5;
}

.comment-submit-btn-div button {
  position: absolute;
  bottom: 0;
  left: 0;
}
.css-pxwk3c-ValueContainer{
  height: auto!important;
}

.css-b62m3t-container {
  height: auto!important;
}

.shadow_dis {
  box-shadow : 0 0.5rem 1rem #cfcaca !important;
}

.css-1olay9j-control {
  height: auto!important;
}
.css-16dj4y9-control {
  height: auto!important;
}

.css-1erf24j-ValueContainer {
  height: auto!important;
}

.message_text_right_n {
  white-space: pre-wrap;
}

.message_text_left_n {
  white-space: pre-wrap;
}

.remove_resize {
  resize: none;
}

.header-notificationn-text {
  display: contents!important;
  font-size: 12px!important;
  color: rgb(255, 255, 255)!important;
  font-family: "Poppins" !important;
}

.mobileHeaderTabImage{
  height: 19px;
}

.footer-second-section-links:hover {
  color: white;
}

/* .user-header-profile {
  margin-top: 13px!important;
} */

.notification_logo_image {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 1px;
}

.opportunity_homepage_content_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.discover_card_div {
  background: #f7f9fb;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height: 68px;
  border: 1px solid #D3D3D3;
  min-width: 60px;
}

.discover_title {
  font-size: 12px;
  font-weight: 600;
  height: 18px;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.discover_text_box {
  width: 80%;
}

.mobile_side_nav {
  height: 100vh!important;
  overflow-y: scroll!important;
  background-color: #f8fafc!important;
}

.prfl_humburger_letters  {
  margin-top: 13px;
}

.userNotAuthenticateForm .input-text{
  height: 50px;
  padding-left: 2.5rem;
  color: black;
  border-radius: 5px;
  border: 1px solid #015A91;
  background: #FFF;
}

.userNotAuthenticateForm .input-placeholder-image{
  position: absolute;
  left: 11px;
  top: 18px;
  width: 16px;
  height: 16px;
}

.userNotAuthenticateForm .select-field{
  height: 50px;
  padding-left: 2.5rem;
  color: black;
  border-radius: 5px;
  border: 1px solid #015A91;
  background: #FFF;
}

.userNotAuthenticateForm .inputClass{
  height: 50px;
  padding-left: 78px!important;
  padding-bottom: 0px;
  color: black;
  border-radius: 5px;
  border: 1px solid #015A91!important;
  background: #FFF;

}

.userNotAuthenticateForm .react-tel-input {
  border: none;
  padding-left: 0px;
}

.userNotAuthenticateForm .flag-dropdown {
  padding-left: 40px;
  position: absolute;
  top:15px;
  height: 10px;
  
}

.userNotAuthenticateForm .input-placeholder-mobile {
  position: absolute;
  left: 11px;
  top: 22px;
  width: 16px;
  height: 16px;
}

.userNotAuthenticateForm .submit-btn {
  height: 48px;
  border-radius: 5px;
  background: #032F3C;
  color: white;
}

.userNotAuthenticateForm .checkbox-input {
  width: 34px;
  height: 34px;
  border-radius: 3px;
  border: 1px solid #032F3C;
  background: #FFF;
}

.userNotAuthenticateForm .term-and-condition-text {
  /*font-family: Poppins;*/
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-left: 14px;
}

.userNotAuthenticateForm .input-textarea{
  border: 1px solid #032F3C;
  padding-left: 40px;
}

.userNotAuthenticateForm .terms-and-privacy-policy {
  color: #0275B1;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-transform: lowercase;
}

.userNotAuthenticateForm .attachment-btn {
  display: flex;
  padding: 6px 26.859px 7.5px 25px;
  align-items: flex-start;
  border-radius: 4px;
  border: 2px solid #626060;
}

.userNotAuthenticateForm .phoneDiv .text-danger {
  margin-top: 15px;
}

.mobile-phone-error {
  margin-top: 25px;
}

.sign-up-checbox-text-label {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 10px;
}
.sign-up-terms-policy-label {
  text-decoration: underline;
}

.sign-up-terms-policy-label:hover {
  text-decoration: underline!important;
}
.checkbox_input_css {
  accent-color : #00303C
}

.request-form .datepicker-css {
  max-height: 40px;
}
.request-form .calenderIcon  {
  top: 5px;
}
.request-form .react-time-picker  {
  max-height: 40px;
}
.request-form .rupeeIcon {
  top: 4px;
}
.bottom_top_navbar_link_show {
  display: none;
}
.bottom_top_navbar_link_hide   {
  display: none;
}

.message_day_show {
  color: white;
  background-color: gray;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 670px) {


  /*latesthomepage*/
  .latest-homepage-div .homepage-content {
    width: 90%;
  }
  .latest-homepage-div .homepage_desc {
    padding: 0px 0px;
  }
  .latest-homepage-div .logo img{
    width: 99px;
    height: 40px;
  }
  .latest-homepage-div .logo-bottom-text {
    font-size: 10px;
  }

  .latest-homepage-div .evolving_to_serve_you_better {
    font-size: 20px;
    font-weight: 700;
  }

  .latest-homepage-div .homepage_desc {
    font-size: 14px;
    font-weight: 400;
  }

  .latest-homepage-div .homepage_thank_text {
    font-size: 14px;
  }

  /*latest homepage end*/
  .bottom_top_navbar_link_show  {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 45px !important;
    height: 60px;
    box-shadow: 0 -5px 5px -5px #333;
  }
  .bottom_top_navbar_link_hide {
    display: none;
    padding-left: 45px !important;
    height: 60px;
    box-shadow: 0 -5px 5px -5px #333;
  }
  
  #afterLoginScreen .offcanvas-collapse-mobile {
    border-radius: 0px!important;
  }
  .CompProfilFlex {
    display: block;
  }
  .before_login_navbar {
    background-color: #032f3c!important;
  }
  .notification_header {
    display: block!important;
    padding: 0px!important;
    margin-right: 25px!important;
  }
  .custom-margin {
    margin-top: 5px;
  }
  .header-notificationn-text{
    display: none!important;
  }
  .header-profile-me-text {
    display: none!important;
  }
  .showSignOutButtonMob {
    display: flex!important;
  }
  .showSignOutBtnDesk {
    display: none;
  }
  .mobile_logo_sm {
    max-width: none;
  }
  .header-div {
    min-height: 64px;
  }
  .prfl_humburger_letters {
    width: 25px!important;
    height: 25px!important;
    margin-bottom: 5px;
  }
  .question_card_style{
    margin-top: 50px;
  }
  .backBtn{
    font-size: 16px;
  }

  .expiryTime{
    font-size: 10px;
  }
  .opportunity_expiry_btn img{
    width: 24px!important;
    height: 24px!important;
  }

  .skills-m{
    margin-top: 25px!important;
  }

  .expiry_row{
    margin-top: -8px;
    /*margin-bottom: -8px;*/
  }
  .oppo_hor_div {
    margin: -8px 0px;
  }
  .user-profile {
    align-items: flex-start!important;
  }
  .oppo_user_detail {
    display: block!important;
  }
  .Oppor_card_chip {
    margin-left: 0px;
    width: fit-content;
    margin-top: -10px;
  }
  .qcard_experience{
    font-size: 10px!important;
  }
  .text_para2 {
    font-size: 10px!important;
  }

  .com-view-like2{
    width: 80%!important;
    justify-content: space-evenly;
  }

  .profile_card{
    margin-top: 10px;
  }
  .profile-qcard-comm-new {
    width: 40px!important;
    height: 40px!important;
  }
  .dflex_comm_send_box  {
    grid-gap: 5px!important;
  }

  .comme-text-n {
    left: -6px;
  }

  .user-header-profile {
    margin-top: -15px !important;
  }
  /*.new_auth_navbar_top {
    grid-template-columns: 6%  1fr 33% 6%;
  }*/

  .styles_new_auth_navbar_top {
    grid-template-columns: 6%  1fr 33% 6%;
  }
  .searchbutton {
    display: none;
  }
  .mobile_search_box_stlye, .login_signup_mobile_view {
    display: none!important;
  }

  /*new mobile responsive design for header */
  .notification_div{
    display: block!important;
    margin: 0px 25px 0 0px!important;
    padding: 0px!important;
  }
  .get_in_touch_text{
    display: none!important;
  }
  .oppor_card_bottom_btn {
    width: auto!important;
  }

  .comment-noti a.active:after {
    border: none!important;
  }

  .user_mobile_profile {
    width: 25px!important;
    height: 25px!important;
  }
  .content_section_div {
    margin-top: 50px!important;
  }
  .oppor_detail_user_feedback {
    display: block!important;
  }
  .Oppor_card_chip_mob {
    margin-left: 0px!important;
    margin-top: 0px!important;
  }
  .opportunity_homepage_content_div {
    display: block;
  }

  /*Sidebar*/
  .mobile-sidebar-nav {
    padding: 10px;
    /*height: 100vh!important;*/
  }
  .mobile-sidebar-nav .sidebar_user_name{
    font-weight: 500;
    font-size: 10px;
    color: #181818;
    cursor: pointer;
    text-transform: capitalize;
  }
  .mobile-sidebar-nav .pefl-icons{
    font-weight: 500;
    font-size: 10px;
    color: #181818;
    cursor: pointer;
    text-transform: capitalize;
    margin-top: -8px;
  }
  .mobile-sidebar-profile-image{
    width: 35px ;
    height: 35px ;
    border-radius: 52px;
  }

  .mobile-quick-links-chip-text{
      color: #000000 !important;
    font-size: 10px;
    font-weight: 500;
  }
  .mobile_nav_bottom_n{
    font-size: 10px!important;
  }
  .mobile_side_nav{
    position: absolute;
    top: 0!important;
    width: 100%!important;
    z-index: 3;
    height: 100vh!important;
    background-color: #f8fafc!important;
  }
  .discover_section_div{
    margin-top: 30px;
  }
  .career_section_div {
    margin-top: 40px;
  }
  .meeting_request_section_div {
    margin-top: 40px;
  }
  .edit_1_section_div {
    margin-top: 40px;
  }
  .edit_2_section_div{
    margin-top: 40px!important;
  }
  .edit_3_section_div{
    margin-top: 40px!important;
  }
  .edit_4_section_div{
    margin-top: 40px;
  }
  .edit_4_section_div{
    margin-top: 40px;
  }
  .certification_section_div {
    margin-top: 40px;
  }
  .help_section_div{
    margin-top: 40px!important;
  }

  /* chat section start */

  .row_style_dflexing {
    /*grid-template-columns : 0fr ;*/
    /*justify-content: initial;*/
    /*grid-gap: 0px;*/
  }
  /*.sidebar_final_select {
    display: block;
    margin-top: 60px!important;
  }*/
  .row_style_dflexing{
    grid-gap: 0px;
  }

  .sidebar_final_select {
    grid-column: 1/3!important;
  }
  .no-chat-screen-container{
    display: none!important;
  }

  .mobile_user_list_hide {
    display: none!important;
  }
  .mobile_user_list_show {
    display: block!important;
  }

  .mobile_chat_hide {
    display: none!important;
  }
  .mobile_chat_show {
    display: block!important;
    position: absolute;
    width: 100%;
    margin-top: 65px!important;
  }
  .mobile-chat-back-btn-div {
    display: flex!important;
  }
  .sidebar_final_select  {
    margin-top: 70px!important;
    border-radius: 7px;
  }

  .no-chat-screen-container {
    display: none;
  }
  .mobile_chat_css {
    position: absolute!important;
    width: 100%!important;
  }

  .chat_input_container  {
    /*bottom: 40px!important;*/
  }
  .main_users_chat_custom{
    height: 63.5vh!important;
  }
  .main_users_chat_container
  {
    height: 66vh!important;
  }

  @media only screen and (min-width: 1050px) {
    .main_users_chat_container{
      height: 66vh!important;
  }}
  @media only screen and (min-width: 1120px) {
    .main_users_chat_container{
      height: 68vh!important;
  }}
  @media only screen and (min-width: 1400px) {
    .main_users_chat_container{
      height: 70vh!important;
  }}
  .rest-of-height {
    height: 91.5vh;
  }
  .mobile_chat_back_btn {
    color: #032F3C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /*chat section end */
}

@media only screen and (max-width: 321px) {
  .discover_title{
    width: 100%;
  }
  .discover_text_box {
    width: 70%;
  }
}