.about-text
{
	height: 30px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #000000;
}

.about-content
{

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #000000;

}

.social-icon-img
{
	border-radius: 9px;
	padding: 4px;
	margin-right: 1vmax;
	width: 32px;
	height: 32px;
}

.user-coolest-project
{
	min-height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
}

.user-coolest-project-description
{
	min-height: auto;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 24px;
}