.user-review-img
{
	width: 39px;
	height: 39px;
	background-color: green;
	border-radius: 40%;
	padding: 10px;
	margin-right: 1vmax;
}

.font_head
{
	display: inline-block;
	margin-left: 10px;
}

.user-review-name-container
{
	display: flex;
	margin-bottom: 10px;
}

.user-review-name
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
}

.user-review-designation
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 150%;

}

.user-review-designation > span
{
	margin-left: 0.5vmax;
}

#review-heading
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}

.review-para
{
	/*width: 700px;*/
	height: 63px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	display: flex;
	align-items: center;
}