















/* =============start responsive screen equal and greater than 1250 ================= */
@media (min-width: 1250px) {

    .row_style_dflexing{
        display: grid;
        grid-template-columns: 6% 21% 1fr 15% 6%;
        /* grid-template-columns: 260px 860px 200px; */
        grid-gap:10px;
        justify-content: center;
    }
    .left_sidebar_space{
        grid-column: 1 / 2;
        grid-row: 1;
        /* background-color: rgb(229, 238, 53);    */
    }
    
    .sidebar_final_select{
        grid-column: 2 / 3;
        grid-row: 1;
        margin-top: 90px !important;
        /* background-color: rgb(127, 227, 255) !important; */
    }
    .mainBoxStyle{
        grid-column: 3 / 4;
        grid-row: 1;
        /* background-color: rgb(241, 22, 124) !important; */
    }
    .sidebar_final_right{
        grid-column: 4 / 5;
        grid-row: 1;
        margin-top: 80px !important;
        /* background-color: rgb(120, 236, 25) !important; */
    }
    .right_sidebar_space{
        grid-column: 5 / 6;
        grid-row: 1;
        /* background-color: rgb(229, 238, 53); */
        
    }

    .public_forum_details_style{
        display: grid;
        grid-template-columns: 8% 15% 1fr 15% 8%;
        grid-gap:8px;
        /* align-items: center; */
    }
    
    .public_forum_left_space{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    .public_forum_left_ads{
        grid-column: 2 / 3;
        grid-row: 1;
    }
    .public_forum_card_page{
        grid-column: 3 / 4;
        grid-row: 1;    
    }
    .public_forum_right_ads{
        grid-column: 4 / 5;
        grid-row: 1;  
    }
    .public_forum_right_space{
        grid-column: 5 / 6;
        grid-row: 1; 
    }
    #afterLoginScreen .admin-sidebar.sideBarAbso .fixedAfterScroll {
        position: fixed;
        top: 85px; 
        width: 21%;
    }
    .my_career_activity_style{
        display: grid;
        grid-template-columns: 16% 1fr 6%;
        grid-gap: 5px;
        padding:  10px 26px !important;
        align-items: center;
        /* justify-content: space-between; */
    }
    .nav_links_manage_request{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
    

    /* styling main page */
    .qcard_head{
        font-size: 1rem;
    }
    .qcard_title_question{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
    .qcard_date{
        font-size: 10px;
    }
    .qcard_read{
        font-size: 12px;
    }

    .text_color_bold{
        color: rgb(6, 6, 6) !important;
        font-weight: 400 !important;
    }
    
    .text_size_bold{
        font-weight: 900 !important;
    }
    .text_para{
        font-size: 14px !important;
    }
    .text_para1{
        font-size: 13px !important;
    }
    .text_para2{
        font-size: 12px !important;
        
    }
    .text_para3{
        font-size: 15px !important;
    }
    .text_para_sign{
        font-size: 18px !important;
        margin-bottom: 0;
    }

    .coming-image-job{
        width: 100%;
    }
    .divtext {
        border: ridge 2px;
        padding: 5px 20px 5px 10px !important;
        width: 100%;
        word-break: break-all;
        cursor: text;
        /*min-height: 2.8em;*/
        overflow: auto;
    }
    .pre-wrap{
        white-space: pre-wrap;
     }
     .pre-line{
        white-space: pre-line;
     }
    
     .inline_box_block{
        display:inline-block
        
    }

    
}

/* ============end responsive screen equal and greater than 1250 ================= */