.my-modal{
	max-width: 507px;
	margin: auto;
}

.modal-body{

}

.img-container{
	display: flex;
	align-items: center;
	justify-content:center ;
	margin-bottom: 10px;
	position: relative;

}
.img-container-first-img{
	position: relative;
	z-index: 0;			
	margin-right: 30px;
}
.img-container-second-img{
position: absolute;
z-index: 1;
top: 50%;
right: 8%;
transform: translate(-50%, -50%);
margin-right: 30px;
}
.img-container img{
	object-fit: cover;
	border: 2px solid black;
	border-radius: 50%;
	width: 100px;
	height: 100px;
}

.coll-req-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.collaborate-on>p{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: rgba(0, 0, 0, 0.6);
}

/*.all-collab-chips{
	display: flex;
	flex-wrap: wrap;
}*/
 .collaborate-on-chips{
	padding: 5.25506px 14.0135px;
	background: #F7F7F7;
	border-radius: 43.7922px;
	cursor: pointer;
}

/*.all-collab-chips div{
	margin-right:0.7em;
}*/
.collaborate-on-chips{
	/*margin-right:0.7em;*/
}

.all-collab-chips .collaborate-on-chips span{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	color: #000000;
}


.text-area-section>label{
	margin-top: 15px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: rgba(0, 0, 0, 0.6);
margin-bottom: 10px;
}

.msg-to-collaboratoror_inp {
  width: 100%;
  background: rgba(217, 217, 217, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 80px; 
  box-sizing: border-box; 
  
}

.send-msg-collab{
	display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 15px;
padding: 12px 10px;
background: #032F3C;
border-radius: 5px;
cursor: pointer;
}
.send-msg-collab>button{
	font-family: 'Poppins';
	background: none;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
border: none;
color: #FFFFFF;
}

/*//////////// SUCCESS POPUP COLLAB MSG////////////*/
.my-modal-1{
max-width: 400px;
	margin: auto;
}

.success-img-container{
	display: flex;
	align-items: center;
	justify-content:center ;
	margin-bottom: 10px;
}

.success-img-container>img{
		object-fit: cover;
	border: 2px solid black;
	border-radius: 50%;
	width: 100px;
	height: 100px;
}
.colloab-request{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-align: center;

color: #000000;
}

.colloab-request>span{
	display: block;
}

.para-confirmation{
	margin-top: 12px;
	margin-bottom: 12px;
	 text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
text-align: center;

color: rgba(0, 0, 0, 0.6);

}

.success-msg-done-div{
	display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 15px;
padding: 12px 10px;
background: #032F3C;
border-radius: 5px;
cursor: pointer;
}
.success-msg-done-div>button{
	font-family: 'Poppins';
	background: none;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
border: none;
color: #FFFFFF;
}