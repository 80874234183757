.no-chat-screen-container{
		height: 600px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
}
.no_chat_xaphal_img_div{
	width: 300px;
}
.no_chat_xaphal_img{
		width: 100%;
		height: 100%;
		object-fit: cover;
}
.no_chat_vertical_line{
	border-right: 3px solid black;
	height: 150px;


}

.no_chat_boy_img{
	width: 80%;
   height: 100%;
	object-fit: cover;
}
.no_chat_collob_para{
	
	width: 53%;
	 margin: auto;
	  text-align-last: center;
	  font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #6C6C6C;
}


.no_chat_collob_encrypt_div {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  color: #6C6C6C;
    text-align-last: center;
}

.no_chat_collob_encrypt_div img {
  margin-right: 5px;
}