.persona_container
{
	width: 100%;

}

.persona_container > div
{
	margin-bottom: 10px
}

.persona-ul
{
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0px;
}


.persona_li
{
	width: 100%;
	margin-top: 14px;
	list-style: none;
	border: 1px solid #47A567;
	border-radius: 20px;
	width: fit-content;
	padding-left: 13px;
	padding-right: 10px;
	padding-bottom: 5px;
	padding-top: 5px;
	/* background: linear-gradient(0deg, rgba(180, 251, 204, 0.3), rgba(180, 251, 204, 0.3)), #f4fff8; */
	background: #f0fef5;
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
}

.li-cancel-btn
{
	background: none;
	border: none;
	font-size: 13px;
}

.li-cancel-btn:hover
{
	background: none;
	border: none;
	color: #47A567;

}

/* .colla_pop_title{
	font-size: 16px;
	
} */

.collab_title_s{
	font-size: 16px;
	font-weight: 500;
}