.user_ask_post_img_cont{
width: 47px;
	height: 47px;
}


.user_ask_post_img{
		width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.user_ask_post_container{
display: flex;
	justify-content: space-between;
	align-items: center;
}
.user_ask_post_name{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12.4976px;
line-height: 19px;
}
.user_ask_post_qualification{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12.4976px;
line-height: 19px;
/* identical to box height */
color: rgba(0, 0, 0, 0.4);
}

.ask_post_input_first_quest{
   width: 100%;
     border: none;
     border-bottom: 0.975216px solid rgba(0, 0, 0, 0.1);
}

/* .ask_post_input_first_quest::placeholder{
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12.4976px;
line-height: 19px;
color: rgba(0, 0, 0, 0.6);
float: left;
} */
.ask_post_AddQuestion{
	 width: 50%;
     border-bottom: 2px solid #032F3C; 
	 padding-bottom: 14px
}
.ask_post_AddOpportunity{
		 width: 50%;
     border-bottom: 2px solid #EFF0F9; 
	 padding-bottom: 14px
}
.AddQuestion_text{
color:  #032F3C ;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
width: 100%;
  text-align: center
}
.AddOpportunity_text{
	color: #999999 ;
	font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
width: 100%;
  text-align: center
}
.ask_post_tags{
	background-color: #E7E7E7 !important;
}
.ask_post_oppor_question{
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;

color: #000000;
}

.ask_post_oppor_question_input{
	width:97%; 
	border: none;
	background: #E7E7E7;
border-radius: 4.77566px;
height: 50px;
}

.ask_post_oppor_question_input::placeholder{
	
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: rgba(0, 0, 0, 0.5);

}

.ask_post_oppor_question_input_white{
	width:97%; 
	background: #ffffff;
	border-radius: 4.77566px;
	height: 50px;
}

.ask_post_oppor_question_input_white::placeholder{
	
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #ffffff;
}