.x-factor-heading{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'poppins';
font-style: normal;
font-weight: 700;
font-size: 97.6075px;
line-height: 0.7em;
letter-spacing: -0.07em;
color: #031634;
}
.x-factor-heading>p{
	margin-left: 1.7em;
font-family: 'poppins';
font-style: normal;
font-weight: 300;
font-size: 36.7533px;
line-height: 37px;
/* identical to box height */

letter-spacing: 2.1em;
text-transform: uppercase;

color: #031634;

}