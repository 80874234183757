.progress-bar {
  background-color: #015c92 !important;
}
.inputClass {
  width: 100% !important;
  border: none !important;
  background-color: transparent !important;
  padding-top: 0;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.form-control.inputClass {
  box-shadow: unset !important;
}
#beforeLoginScreen .otpInputs,
#afterLoginScreen .otpInputs {
  width: 50px !important;
  height: 50px !important;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #c3bdbd;
}
#beforeLoginScreen .otpInputs:focus,
#afterLoginScreen .otpInputs:focus {
  border: 1px solid #015c92;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
  color: #015c92;
}

.resendCode {
  color: #015c92;
  cursor: pointer;
}
.resendCode:hover {
  transform: scale(1.1, 1.1);
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #015c92;
  width: 30px;
  height: 30px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.add-btn:hover,
#beforeLoginScreen .radio-img:hover {
  transform: scale(1.05, 1.05);
}

li.get-in-touch:hover,
#beforeLoginScreen ul.footer-li li:hover {
  transform: scale(1.03, 1.03);
}

.inputImage {
  height: 100px;
  width: 100px;
  padding: 10px;
  object-fit: contain;
}

#afterLoginScreen .signup-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1rem auto;
}
#afterLoginScreen .signup-steps:before {
  content: "";
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d5d5d5;
  width: 94%;
  height: 1px;
  margin: auto;
}
#afterLoginScreen .step {
  display: grid;
  text-align: center;
  grid-gap: 2px;
  z-index: 99;
}
#afterLoginScreen .num-step {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  color: #015c92;
  border-radius: 50%;
  line-height: 0.5;
  margin: auto;
}
#afterLoginScreen .num-step.active {
  border: 6px solid #015c92;
}
#afterLoginScreen .num-step.active.done {
  background-color: #015c92;
}

#afterLoginScreen .Other-radio .checkbox {
  margin-right: 1vw;
}
#afterLoginScreen .Other-radio .checkbox:last-child {
  margin-right: 0;
}

#afterLoginScreen .checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
#afterLoginScreen .form-style .form-control {
  background-color: #fff;
  height: 45px;
  padding-left: 45px;
  color: #626060;
  /* box-shadow: 5px 5px 20px 0px rgb(0 0 0 / 15%); */
  border-radius: 10px;
}

#afterLoginScreen .AddtoList,
#afterLoginScreen .AddtoList:hover,
#afterLoginScreen .AddtoListpath,
#afterLoginScreen .AddtoListpath:hover {
  border: 2px solid #065f94;
  border-radius: 5px;
  color: #065f94;
  width: 100%;
  cursor: pointer;
  font-weight: 800;
}

#afterLoginScreen .remove img {
  float: right;
  width: 20px;
  cursor: pointer;
}

#afterLoginScreen .starIcon {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c2c2c2;
}

#afterLoginScreen .starIcon img {
  width: 24px;
  display: flex;
  gap: 10px;
  /* border-radius: 21px; */
  justify-content: center;
  align-items: center;
}

#afterLoginScreen .form-control:focus,
.passcode-wrapper input:focus {
  border: 1px solid #015a91;
}

#afterLoginScreen .form-style .Other-radio.Recrutiers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 386px;
  margin: auto;
}

#afterLoginScreen .reactSelect div::-webkit-scrollbar {
  width: 0px;
}
#afterLoginScreen .bg-span span:last-child {
  background-color: #e5eef4;
}
#afterLoginScreen .rbt-input-main.form-control.rbt-input {
  padding: 0.375rem;
  display: block !important;
}

#afterLoginScreen .checkbox input[type="text"]:focus {
  border: 0;
}

#afterLoginScreen .page-item.active .page-link {
  background-color: #1f2629;
  border-color: #1f2629;
  color: white;
}
#afterLoginScreen .page-link {
  color: #1f2629;
  fontsize: 13px;
}
#afterLoginScreen .store-sidebar .shadow-part {
  padding: 0rem 0 0 10px;
}

#afterLoginScreen .infinite-scroll-component {
  overflow: unset !important;
}

#beforeLoginScreen .starBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#afterLoginScreen .rbt-input-multi .rbt-input-wrapper {
  overflow-y: scroll;
  height: 50px;
}
#afterLoginScreen .rbt-input-multi .rbt-input-wrapper::-webkit-scrollbar {
  width: 0;
}

#afterLoginScreen .rbt-token {
  color: #015c92;
}

/* #afterLoginScreen .AskQuetion:hover {
  transform: scale(1.02, 1.02);
} */

#afterLoginScreen .questionsLL.flag-icon {
  position: relative;
  top: -20px;
  left: unset;
  margin-left: 10px;
  width: unset;
}
#afterLoginScreen .hgt {
  height: 180px;
}

#afterLoginScreen .rowBottom {
  position: absolute;
  bottom: -45px;
  left: 30px;
  padding-bottom: 7px;
}
#afterLoginScreen .rowBottom2 {
  position: absolute;
  bottom: -55px;
  padding-bottom: 7px;
}
#afterLoginScreen .com-view-like.rowBottom3 {
  bottom: -40px;
  padding-bottom: 7px;
}

#afterLoginScreen .emp-img.questionsL {
  width: 39px !important;
  height: 39px !important;
  /* object-fit: unset; */
}

#afterLoginScreen .lcone {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

#afterLoginScreen .progressTooltip {
  position: absolute;
  top: 40px;
  right: 0;
  font-size: 12px;
  font-family: "Poppins";
}

#afterLoginScreen .animated-progress {
  width: 100%;
  height: 6px;
  border-radius: 18px;
  background-color: #dedede;
  overflow: hidden;
}
#afterLoginScreen .progressTooltip:before {
  content: "";
  position: absolute;
  top: -13px;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 0px solid #015c9203;
  border-bottom: 12px solid #015c92;
  border-left: 6px solid #015c9200;
  border-right: 7px solid #015c9200;
  width: 13px;
}

#afterLoginScreen .bg-span.gridLay {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
#afterLoginScreen .moveUp {
  top: -20px;
  position: absolute;
  right: 0;
}
#afterLoginScreen .likeImg {
  height: 30px;
  width: 30px;
}

#afterLoginScreen .certificate .plus-btn {
  position: absolute;
  /* bottom: 10px; */
  right: 15px;
  top: -15px;
}
#afterLoginScreen .detailCartification {
  width: 30vw;
}

#afterLoginScreen .counselo-veiw-img {
  margin: 2rem 0 0 0;
}
.rowBottom2 .bg-span {
  white-space: nowrap;
}
.white-space {
  white-space: nowrap;
}
.linkBreak {
  word-break: break-all;
}
#afterLoginScreen .rowBottom {
  bottom: -45px;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}
@media (max-width: 991.9px) {
  #afterLoginScreen .rowBottom,
  #afterLoginScreen .rowBottom2 {
    position: static;
  }
  #afterLoginScreen .com-view-like.rowBottom3 {
    bottom: 0;
  }
  #afterLoginScreen .hgt {
    height: 100%;
  }
  #afterLoginScreen .counselo-veiw-img {
    margin: 2rem 0;
  }
  /* #afterLoginScreen .emp-img.questionsL {
    width: 100px !important;
    height: 100px !important;
  } */
}

@media (max-width: 1199.9px) {
  .rowBottom2 .bg-span {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-bottom: 20px;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: transparent;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 0;
}
.upload-btn-wrapper .btn {
  border: 0px dotted #015c92;
  background-color: #fff;
  padding: 0;
  border-radius: 50%;
  text-align: -webkit-center;
  margin: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.upload-btn-wrapper .btn img {
  width: 130px;
  height: 130px;
  margin: auto;
  border-radius: 70px;
}
.camera-icon {
  width: 30px;
  height: 30px;
  margin: auto;
  position: absolute;
  bottom: 5px;
  left: 1px;
  right: 0;
  border-radius: 50%;
  background-color: #015c92;
  color: #fff;
  font-size: 15px;
  line-height: 33px;
}
.upload-btn-wrapper input[type="file"] {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#afterLoginScreen .AskQuetion {
  width: 100px;
  font-size: 14px;
  border-radius: 10px;
  position: relative !important;
  top: unset;
  bottom: unset;
  right: unset;
  height: 40px;
}
#afterLoginScreen .dFlex {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
}

.proleStuImg {
  /* width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  object-fit: cover;
  margin-bottom: 25px; */
  display: inline-flex;
  position: relative;
  top: -42px;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 6px #ffffff solid;
  color: #ffffff;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 25px;
  text-align: center;
}
.userProIcon {
  position: absolute;
  top: 5px;
  left: -5px;
  margin-right: 2px;
}
.floatrightText {
  margin-left: 7rem;
}
.pl-10 {
  padding-left: 6rem;
}
.pl-9 {
  padding-left: 5rem;
}
.userProIcon.collle {
  top: -10px;
}
.CollegeDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px 10px;
}

.starIcon {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c2c2c2;
}
.starIcon img {
  width: 24px;
}

.form-control.txtArea {
  height: 150px !important;
}

#beforeLoginScreen .txtArea::-webkit-scrollbar {
  width: 0;
}

#afterLoginScreen .rbt-token.rbt-token-removeable {
  margin-top: 10px !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(52, 152, 219, 0.15) !important;
}

#afterLoginScreen .skills .bg-span span {
  margin-right: 5px;
}
#afterLoginScreen .mentorss li span {
  cursor: pointer;
  font-size: 13px;
}

.reactSelectContainer div::-webkit-scrollbar {
  width: 0;
}
#afterLoginScreen .profile-star {
  position: absolute;
  bottom: 10px;
  gap: 85px;
}
.h-200 {
  height: 200px;
}

.h-230 {
  height: 230px !important;
}

.h-190 {
  height: 190px !important;
}
#afterLoginScreen .ellipes-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#afterLoginScreen .priceAmount {
  color: #015c92;
}
#afterLoginScreen .serviceIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#afterLoginScreen .servicesTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
#afterLoginScreen .serviceIcons .short-img-bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  object-fit: cover;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #015c92;
}

input:focus + .slider {
  box-shadow: 0 0 1px #015c92;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.color-primary {
  color: #015c92;
}
.requestImageSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.font-size-small {
  font-size: small;
}
.btn-primary {
  background-color: #015c92;
  color: white;
}
.reject-btn {
  border: 2px solid #015c92;
  color: #015c92;
  height: 40px;
  border-radius: 5px;
}
.accept-btn {
  border: transparent;
  background-color: #015c92;
  color: white;
  border-radius: 5px;
}

.accept-btn:hover {
  color: white;
  transform: scale(1.01, 1.01);
}
.reject-btn:hover {
  color: #015c92;
  transform: scale(1.01, 1.01);
}
#afterLoginScreen .overFlowXScroll {
  overflow-x: scroll;
  width: auto;
  overflow-y: hidden;
}

/* #afterLoginScreen .mentorss.overFlowXScroll::-webkit-scrollbar {
  width: 0px !important;
} */
.react-time-picker__inputGroup__divider
{
  display:none;
}
.react-time-picker__wrapper {
  border: 0px !important;
  
}
.react-time-picker__inputGroup__input
{
  /* display: none !important;; */
  padding-left: 0px;
  opacity:0.5;
}
.react-time-picker__inputGroup__amPm
{
  /* display:none; */
  padding-left: 10px;
  
}
.react-datepicker__input-container
{
  

}
.react-time-picker--enabled
{
  border: 1px solid rgba(0, 0, 0, 0.20)!important;
}
.react-time-picker--enabled:hover
{
  border: 1px solid var(--blue-secondary, #3D46AE)!important;
}

.datecss
{
  border: 1px solid rgba(0, 0, 0, 0.20)!important;
 
}
.datecss:hover
{
  border: 1px solid var(--blue-secondary, #3D46AE)!important;
}
.timeecss
{
  opacity: 0.5 !important;
}

.rupeeIcon {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 15px;
}

.calenderIcon {
  position: absolute;
  top: 15px;
  left: 10px;
  height: 15px;
}

.completedButton {
  padding: 5px 5px;
  float: right;
  border-radius: 5px;
  color: rgba(33, 190, 33, 0.9);
  background-color: rgba(144, 238, 144, 50%);
}
.cancelledButton {
  padding: 5px 5px;
  float: right;
  border-radius: 5px;
  font-size: 500;
  color: rgba(128, 8, 32, 0.674);
  background-color: rgba(243, 84, 116, 0.472);
}
.positionAbsolute {
  position: absolute;
  bottom: 10px;
  width: 90%;
}

/* width */
#afterLoginScreen .overFlowXScroll::-webkit-scrollbar {
  height: 5px;
  cursor: pointer;
}

/* Track */
#afterLoginScreen .overFlowXScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#afterLoginScreen .overFlowXScroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
  opacity: 0;
}

/* Handle on hover */
#afterLoginScreen .overFlowXScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leaderboard-img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
.shareContainer {
  position: absolute;
  bottom: -2rem;
  right: 2rem;
  height: 40px;
  width: 150px;
  z-index: 99999;
}
.shareIcons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

#afterLoginScreen .add-btn.editBtn {
  text-align: center;
  line-height: 10px;
}

#beforeLoginScreen .img-comment {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: contain;
}

#beforeLoginScreen .comme-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

#beforeLoginScreen .bol-lft:before,
#afterLoginScreen .bol-lft:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 32px;
  background-color: #ddd;
  height: 85%;
  width: 1px;
  display: block;
  margin: auto;
  z-index: -1;
}
#beforeLoginScreen .comment-format {
  position: relative;
  padding-left: 58px;
  margin-top: 10px;
}

#beforeLoginScreen .comme-text {
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}
#beforeLoginScreen .comme-text-name {
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  padding: 5px;
  /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%); */
}

#beforeLoginScreen .commentInputBtn {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

#beforeLoginScreen .comme-text:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -12px;
  border-right: 6px solid #015c92;
  border-top: 6px solid #015c9200;
  border-left: 6px solid #015c9200;
  border-bottom: 6px solid #015c9200;
}
/* #beforeLoginScreen p {
  font-family: "Poppins";
} */

#beforeLoginScreen .com-col {
  color: #54b3cd;
}

#beforeLoginScreen .cursor-pointer {
  cursor: pointer;
}

#beforeLoginScreen .commentInputBtn .add-btn {
  width: fit-content;
  white-space: nowrap;
}

#beforeLoginScreen .add-btn {
  background-color: #032f3c;
  padding: 11px 12px;
  text-transform: capitalize;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

#beforeLoginScreen .comme-text:before {
  content: "";
  position: absolute;
  top: 14px;
  left: -12px;
  border-right: 6px solid #015c92;
  border-top: 6px solid #015c9200;
  border-left: 6px solid #015c9200;
  border-bottom: 6px solid #015c9200;
}

#beforeLoginScreen .form-control {
  height: 50px;
  border-radius: 5px;
  border: 0;
  padding-left: 2.5rem;
  color: #b7b8ba;
}

#beforeLoginScreen span.flag-icon.form-flag {
  right: 0;
  left: unset;
  top: 0;
}

#beforeLoginScreen .forumDetailBeforeLogin.flag-icon {
  background-color: transparent !important;
}

.careerCards {
  height: 330px;
}

.careerButton {
  position: absolute;
  bottom: 0px;
  height: 40px;
}

.specilizationTags span {
  white-space: nowrap;
  margin: 10px 5px 0 0;
  display: inline-block;
  background-color: rgb(1, 92, 146, 0.15);
  font-family: "Poppins";
  color: #015c92;
  padding: 5px 14px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
}

.add-bttn {
  background-color: #015c92;
  color: white;
}

.flag-icon.form-flag img {
  width: 25px;
}

ul.nav.nav-tabs.group-nav.mentorss {
  display: flex;
  gap: 15px;
}

#afterLoginScreen .nav.nav-tabs.group-nav.mentorss .nav-item {
  margin-right: 0;
  min-width: unset;
}

#afterLoginScreen .nav.nav-tabs.group-nav.mentorss .nav-link {
  padding: 10px 0 8px 0;
}

#afterLoginScreen .nav.nav-tabs.group-nav .nav-link {
  cursor: pointer;
}

.centerDivTable {
  display: table;
  height: 70vh;
  width: 100%;
}

.centerDivCell {
  display: table-cell;
  vertical-align: middle;
}
.availabilityCard {
  padding: 10px;
  margin: auto;
  text-align: center;
}
.availabilityCardWrapper input[type="radio"] {
  display: none;
}

.availabilityCardWrapper input[type="radio"]:checked + label {
  border: 2px solid #015c92;
}

#afterLoginScreen .d-grids {
  display: grid;
  grid-gap: 10px;
}

.midText h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.midText h2 span {
  background: #fff;
  padding: 0 10px;
}

/* .midText:hover {
  transform: scale(1.02, 1.02);
} */

.midText {
  overflow: hidden;
  text-align: center;
}

.midText:before,
.midText:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.midText:before {
  right: 0.5em;
  margin-left: -50%;
}

.midText:after {
  left: 0.5em;
  margin-right: -50%;
}

.mdl-ttl {
  text-align: center;
  padding-top: 40px;
  font-weight: bolder;
  font-size: 36px;
  color: #015c92;
}

.form-style {
  max-width: 800px;
  margin: 35px auto 25px;
}
.input-container {
  position: relative;
}
#afterLoginScreen .form-style .form-group .form-control,
#afterLoginScreen .form-style .form-group .form-control:focus {
  height: 60px;
  border-radius: 5px;
  border: 1px solid #015c92;
  /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important; */
}

#afterLoginScreen .theme-color {
  color: #015c92;
}

#afterLoginScreen .pass-input.chngePass {
  top: 20px;
}

#afterLoginScreen .react-datepicker-popper {
  z-index: 999;
}

#afterLoginScreen .form-style .form-group .form-control.inputClass {
  box-shadow: unset !important;
}

.detailAddress {
  grid-template-columns: repeat(2, 1fr);
  max-width: 500px;
  margin: auto;
  grid-gap: 20px;
}

.detailAddress2 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.fw-normal {
  font-weight: 100 !important;
}
.fw-bold {
  font-weight: 700 !important;
}

.userDetailIconMain .userProIcon {
  /*left: 20px;*/
}

.ratingBox {
  background-color: lightgrey;
  padding: 4px 10px;
  border-radius: 5px;
  color: black;
  margin: 0;
}
.ratingContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}
.filterDrop:hover .filterDropSelect {
  display: block;
}
.filterDropSelect.Categoryfilter {
  width: 25rem;
  padding-bottom: 20px;
}
.filterDropSelect {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  display: none;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  width: 14rem;
  z-index: 999;
}
.Categorycheckbox {
  grid-template-columns: repeat(1, 1fr);
  /* grid-gap: 8px 9px; */
}
.checkboxs [type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkboxs label {
  position: relative;
  display: inherit;
}

.checkboxs {
  box-shadow: unset;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3px 20px;
  text-align: -webkit-center;
  align-items: center;
}

.checkboxs [type="checkbox"] + div {
  padding: 19px 20px;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 6%);
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.checkboxs.Categorycheckbox [type="checkbox"] + div.div-bg {
  padding: 5px 0;
  border-radius: 30px;
  border: 1px solid #212529;
}

.checkboxs label p {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #212529;
}

.checkboxs [type="checkbox"]:checked + div {
  background: #015c92;
  border-radius: 3px;
  color: #fff;
}

.checkboxs [type="checkbox"]:checked + div.div-bg p {
  color: #fff;
}
#afterLoginScreen .filterContainer .form-control {
  height: 38px;
  color: black;
}

#afterLoginScreen .Other-Skills-checkbox.VerifySkills .checkbox label:before {
  position: absolute;
  right: -30px;
  top: -10px;
  border-radius: 50%;
  margin: unset;
  border: 0px solid #4991a1;
  content: "+";
  font-size: 20px;
}

.checkInput .form-control:focus {
  border: 0px !important;
  outline: 0px !important;
}

.h-40 {
  height: 36px !important;
}

.mediaButton {
  border: 1px solid #015a91;
  padding: 10px;
  border-radius: 30px;
  color: #015a91;
  background-color: transparent;
}

#afterLoginScreen .employment .form-group .input-img {
  top: 20px;
}

.imageDeleteIcon {
  position: absolute;
  top: 0;
  right: -20px;
  height: 15px;
  width: 15px;
  cursor: pointer;
}

/* #afterLoginScreen .short-cuts {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
} */

#afterLoginScreen .short-cuts::-webkit-scrollbar {
  height: 5px;
}

/* Track */
#afterLoginScreen .short-cuts::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#afterLoginScreen .short-cuts::-webkit-scrollbar-thumb {
  background: #888;
}

@media (max-width: 1480.9px) {
  #afterLoginScreen .short-cuts {
    white-space: nowrap;
    overflow: hidden;
    /* overflow-x: scroll; */
  }
}

#afterLoginScreen .page-search {
  width: 100%;
}

#afterLoginScreen form.has-search {
  width: 100% !important;
  position: relative !important;
  margin-left: 35px !important;
}

.ratingFormStyle {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.ratingFormStyle .textarea-control {
  height: 100px !important;
  width: 100%;
}
@media (max-width: 441.9px) {
  #afterLoginScreen .emp-img.questionsL {
    width: 50px !important;
    height: 50px !important;
  }
}
.react-tel-input .form-control {
  width: 100% !important;
  box-shadow: unset !important;
  border-radius: 5px !important;
}

.footerGrid {
  grid-template-columns: repeat(3, 4fr);
  column-gap: 30px;
}

.fs12 {
  font-size: 12px !important;
}

.dateTimeFles.changedOne {
  grid-gap: 12px !important;
}
.ellips2 {
  -webkit-line-clamp: 2;
}

@media (max-width: 1299.9px) {
  #afterLoginScreen .addWallet {
    font-size: 14px;
  }
  .paymentIcons {
    width: 100%;
  }
}

.no-wrap {
  white-space: nowrap;
}
.gap-1 {
  gap: 1px !important;
}

.borderSolid {
  border: 1px solid #015a91 !important;
}

#beforeLoginScreen .counsellerCrossIcon .remove img {
  position: absolute;
  right: 0;
  /* float: right; */
  top: 0;
}

.careerPathImageContainer img.career-detail-icon {
  width: 50% !important;
  /* height: 100px !important; */
  float: left;
}

.emojiIcon {
  justify-content: center;
  align-items: flex-end;
  bottom: 5px;
}

.emojiIcon img {
  height: 20px;
  width: 20px;
  margin: auto;
}
