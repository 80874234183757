.x-factor-festival-container{
background: linear-gradient(92.36deg, #04619F 0%, #1C1C1C 100%);	
}

.x-factor-title{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-transform: capitalize;
color: #FFFFFF;
}

.view-text{
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 15px;
text-decoration-line: underline;

color: rgba(255, 255, 255, 0.8);
}


.festival-date , .festival-time{
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 15px;

display: flex;
align-items: center;
text-transform: uppercase;

color: #FFFFFF;
margin-left: 6px;
}
.x-factor-festival-btn-container{
	display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 7px 36px;
gap: 10px;
cursor: pointer;
height: 29px;

background: #FFFFFF;
border-radius: 4px;
margin-top: 16px;
}

.x-factor-festival-btn{
	border: none;
	background:none;
height: 15px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 15px;
display: flex;
flex-direction: column;
justify-content: center;
text-transform: uppercase;
color: #032F3C;
}



