.img-user-collab{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover
}
.img-user-collab_double_img{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover
}

.notification_colla_req_msg_s{
	display: grid;
	grid-template-columns: 55px 1fr;
	grid-gap:8px;
	/* align-items: center; */
}

.notification_user__char{
	display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 26px;
    font-weight: 600;
    background-color: #032F3C;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
}
.want-to-collab-para{
	margin-bottom: 5px;
	 color: #626262;
	 font-weight: 500;
	  font-size: 15px
}
.collab-on-notification-btn{
	padding: 5px 10.1818px;
	 background: #F7F7F7;
	  border-radius: 31.8182px;
	   font-family: 'Poppins';
	    font-style: normal;
		 font-weight: 500;
		  font-size: 12px;
		   line-height: 12px;
		    margin-right: 8px;
}
.collab-notification-time-date{
	color: #626262;
	 border-radius: 31.8182px;
	  font-family: 'Poppins';
	   font-style: "normal";
	    font-weight: 500;
		 font-size: 14px;
		  margin-left: 3px
}
.notifi-intrest-btn{
	background: #032F3C;
	 padding: 3px 20px;
	  color: white;
	   border-radius: 3.53132px;
	    margin-right: 5px;
		font-weight: 500;
font-size: 12px;
line-height: 18px;
height: 28px;
		
}
.notifi-intrest-btn_red{
	background: rgba(255, 30, 60, 0.02);
	 padding: 3px 20px;
	  color: #032F3C;
	   border-radius: 3.53132px;
	    margin-right: 5px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
font-size: 12px;
line-height: 18px;
height: 28px;
}
.notifi-ignore-btn{
	background: rgba(0, 0, 0, 0.2);
	 padding: 2px 12px;
	  color:  rgba(0, 0, 0, 0.5);
	   border-radius: 3.53132px;
	    border: none;
		
height: 28px;
}

.notifi-intrest-btn-wallet-green{
	background: #fff;
	 padding: 3px 20px;
	   border-radius: 3.53132px;
	    margin-right: 5px;
		font-weight: 700;
font-family: 'Poppins';
font-style: normal;
font-size: 16px;
line-height: 24px;
color: #47A567;
border: 1px solid rgba(0, 0, 0, 0.2);
}
.notifi-intrest-btn-wallet-red{
	background: #fff;
	 padding: 3px 20px;
	   border-radius: 3.53132px;
	    margin-right: 5px;
		font-weight: 700;
font-family: 'Poppins';
font-style: normal;
font-size: 16px;
line-height: 24px;
border: 1px solid rgba(0, 0, 0, 0.2);
color: #FD4249;
}


.notifi-intrest-btn-BlackAndWhite{
color: #032F3C;
background: #fff;
	 padding: 3px 20px;
	   border-radius: 3.53132px;
	    margin-right: 5px;
		font-weight: 500;
line-height: 24px;
font-weight: 500;
font-size: 12px;
line-height: 18px;
}


.notification_text{
	font-weight: 500;
font-size: 24px;
line-height: 36px;
}

.notification_mark_as_read{
	font-weight: 400;
font-size: 14px;
line-height: 20px;
text-decoration-line: underline;

color: rgba(0, 0, 0, 0.5);

}

.notification_count_bar{
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 35px;
}

.notification_Request_text{
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: rgba(0, 0, 0, 0.6);
}
.notification_All_text{
	font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #3D46AE;
}



.notification_count_pending_All{
	padding: 0px 10px; 
	margin-left: 4px;
	margin-bottom: 2px;
	background: #3D46AE;
border-radius: 25px;
	   color: rgb(249, 246, 246);
	   min-width: 36px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   justify-content: center;
}
.notification_border_bottom_All{
border-bottom: 2px solid #3D46AE;
	padding: 0px 6px;

}

.notification_count_pending_Request{
	 padding: 0px 10px; 
	margin-left: 4px;
	margin-bottom: 2px;
	border: 1px solid rgba(0, 0, 0, 0.3);
border-radius: 25px;
	  border-radius: 25px;
color: rgba(0, 0, 0, 0.6);
min-width: 36px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   justify-content: center;
}


.notification_xaphal_icon_div{
	width: 60px;
	height: 60px	;
}

.notification_xaphal_icon{
	width: 100%;
	height: 100%;
object-fit: cover;
}

.notification_welcope_xaphal{
	font-weight: 500;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;

color: #1A1F30;

}
.notification_time_xaphal_icon{
	font-weight: 500;
font-size: 12px;
line-height: 18px;
color: rgba(0, 0, 0, 0.5);
}
.notification_img_container{
	width: 60px;
	 height: 60px;
	  border-radius: 50%;
	  overflow: hidden; 
	  flex-shrink: 0
}

.notification_img_container_double_img{
	position: relative;
	width: 44px;
	 height: 44px;
	  border-radius: 50%;
	  overflow: hidden; 
	  flex-shrink: 0;
	  margin-left: -4px;
}

@media (max-width: 1191px) {
	.notification_img_container_double_img_screen_size{
		position: absolute;
		 right: 0;
		  left: 30.3px;
		   top: 35.3px !important;
	}
	.notification_img_container_double_img_screen_size_comnt{
		position: absolute;
		 right: 0;
		  left: 30.3px;
		   top: 35.3px !important;
	}
}


/*
.notification_img_container_double_img-2{
	 position: "absolute";
                      right: 0;
                      left: 30.3px;
                      top: 18.3px;
                      bottom: 0;
	  flex-shrink: 0
}*/

.notification_time_para_conatiner{
	flex-wrap: nowrap; margin-left: 16px; margin-top: 5px;
}
.notifcations_text_img{
	width: 100%;
	height: 100%;
	background-color: #032F3C;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size:x-large;
	font-weight: 600;
}