	.opportunity_bar_chip_container{
	margin: auto;
	 display: flex;
  flex-wrap: nowrap;
}

.opportunity_bar_chips{
	  padding: 5px 10px;
	   border-radius: 25px;
	    border: 1px solid #E4E8F1;
        background: white; 
		flex:  1 0 content;     
				  
}
.ask_post_forums_oppor{
	   font-weight: 600;
                font-size: 16px;
                line-height: 24px
}

.opportunity_bar_chip_text{
	font-weight: 500; 
	font-size: 14px;
	 line-height: 18px;
}
.opportunity_open_to{
	  font-weight: 700;
                    font-size: 20px;
                    line-height: 30px;
}
.opportunity_open_to_div{
max-width: 50px; 
max-height: 50px;

}
.opportunity_open_to_img{
	width: 100%; 
	height: 100%;
   border-radius: 50%;
object-fit: cover;
}

.Oppor_card_name{
	font-weight: 700;
	 font-size: 12px;
}

.Oppor_card_date_row{
	color: rgba(0, 0, 0, 0.7);
	 font-size: 12px

}

.Oppor_card_chip{
	max-width: 100%;
	height: fit-content;
    padding:8px;
    border-radius: 25px;
	background: #E5F9F7;
	margin-left: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items:center;
	white-space: nowrap;
	
}
.Oppor_card_chip_text{
	width: 100%;
	  font-weight: 600;
      font-size: 12px;
   line-height:18px;
    color: #0BCBB3;
	
	
}

.Oppor_card_para {
	font-weight: 400 !important;
	font-size: 12px !important; 
	line-height: 18px !important;
}

.Oppor_card_horizonLine{
	width: 100%;
	 margin-left: -15px;
	  padding-right: 15px;
}

.oppor_card_bottom_btn{
	background-color: #032F3C;
	 border-radius: 5px;
	  padding: 5px 10px;
}
.opportunity_open_to_div_img_text{
	height: 39px;
	width: 39px;
   	border-radius: 50px;
    font-size: 23px;
    font-weight: 600;
    background-color: #032F3C;
    color: #FFFFFF;
    cursor: pointer;
	/*text-align: center;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}