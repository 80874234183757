.mobile_show_activity {
  display: none;
}

.text_color_bold {
  color: rgb(6, 6, 6) !important;
  font-weight: 400 !important;
}

.text_size_bold {
  font-weight: 900 !important;
}

.text_para {
  font-size: 14px !important;
}

.text_para1 {
  font-size: 13px !important;
}

.text_para2 {
  font-size: 12px !important;
}

.text_para3 {
  font-size: 15px !important;
}

.text_para4 {
  font-size: 16px !important;
}

.text_para10 {
  font-size: 10px !important;
}

.text_para11 {
  font-size: 11px !important;
}

.text_para_sign {
  font-size: 18px !important;
  margin-bottom: 0;
}

.text_head {
  font-size: 34px !important;
}

.text_desc {
  font-family: "Inter";
  font-weight: 400;
  font-size: 13;
  line-height: 20px;
}

.qcard_head {
  font-size: 1rem;
}

.sec_head {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.qcard_title_question {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.qcard_work_experience {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
  position: relative;
  bottom: 3px;
}

.qcard_work_experience_month_ago {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px !important;
  color: black;
}

.dot_month_ago_style {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  display: inline-block;
  background-color: #808080;
}

.qcard_date {
  font-size: 10px;
  /* font-weight: 560; */
}

.qcard_read {
  font-size: 12px;
}

.main_content_body {
  display: grid !important;
  grid-template-columns: 4fr 6fr !important;
  grid-gap: 10px !important;
}

.qcard_user_name_style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.close_btn_modal {
  border: none;
  font-size: x-large;
  font-weight: bolder;
  cursor: pointer;
  margin-top: -12px;
}

.prfl-image {
  width: 70px !important;
  height: 70px !important;
}

.nav_links_manage_request {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.manage_request_active {
  /* border-bottom: 2px solid #0077b5; */
  font-weight: 500;
  color: #032f3c;
}

/* .manage_request_inactive{
    border-bottom: 2px solid #040404;
} */
.tagged_skill_style {
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 400;
}

.public_profile_btn {
  padding: 6px 12px !important;
  font-size: 11px;
}

.my_activity_style {
  padding: 10px !important;
}

.meeting_request_style {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.my_career_activity_style {
  display: grid;
  grid-template-columns: 16% 1fr 6%;
  grid-gap: 5px;
  padding: 10px 26px !important;
  align-items: center;
  justify-content: space-between;
}

.image_humburger {
  width: 31px !important;
}

.comment__img {
  width: 45px !important;
  height: 45px !important;
}

.add_money_btn {
  /* padding: 4px 2px 3.1px 2px; */
  /* padding-left: 2.5px;
    padding-right: 2.5px;
    padding-bottom: 6.3px; */
  /* border: 2px solid #217BF4; */
  border-radius: 5px;
  font-size: 16px;
  /* background-color: #217BF4; */
  color: #ffffff;

  /* outline: 1px double #217BF4; */
}

.inside_add_money_btn {
  background-color: #e8f2fb;
  padding: 5px 10px;
  color: #217bf4;
  border-radius: 5px;
  font-size: 14px;
  border: 2px solid #217bf4;
  /* outline-color: #217BF4;
    outline-style: double;
    outline-width: 5px; */
}

.add_money_btn :hover {
  color: #217bf4 !important;
}

.ruppe_amount_style {
  font-size: 24px !important;
}

/* .my-modal {
    width: 50vw;
    max-width: 50vw;
} */

.bottom_top_navbar_link,
.mobile_search_box_stlye,
.login_signup_mobile_view {
  display: none;
}

#afterLoginScreen .bottom_navigation_style {
  padding: 15px 5px 0 5px;
  margin: 0px 15px 0 0px;
  position: relative;
  border-radius: 12px;
  text-align: center;
  text-transform: capitalize;
}

#afterLoginScreen .bottom_navigation_style:last-child {
  margin: 0px 0px 0 0px;
  padding: 1px 9px 0 5px;
}

#afterLoginScreen .bottom_navigation_style img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: auto;
}

#afterLoginScreen .bottom_navigation_style a {
  display: grid;
  grid-gap: 6px;
}

#afterLoginScreen .bottom_navigation_style a p {
  font-size: 14px;
  /* font-weight: 600 !important; */
  color: #000;
}

#afterLoginScreen .bottom_navigation_style a.active p,
#afterLoginScreen .bottom_navigation_style a:hover p {
  color: #127db6;
  font-weight: 550 !important;
}

#afterLoginScreen .bottom_navigation_style a.active:after,
#afterLoginScreen .bottom_navigation_style a:hover::after {
  content: "";
  position: absolute;
  border-bottom: 2px solid #032f3c;
  bottom: -7px;
  left: 0;
  width: 100%;
}

#afterLoginScreen .bottom_navigation_style span {
  position: absolute;
  background-color: #032f3c;
  top: -6px;
  right: -3px;
  color: #fff;
  width: 17px;
  height: 17px;
  font-size: 10px;
  line-height: 19px;
  border-radius: 50%;
  text-align: center;
}

#afterLoginScreen .bottom_navigation_style span.notiSpan {
  right: 21px;
}

#afterLoginScreen .bottom_navigation_style a:hover div span,
#afterLoginScreen .bottom_navigation_style a.active div span {
  color: #fff;
}

#afterLoginScreen .bottom_navigation_style a.active .inactive-icon,
#afterLoginScreen .bottom_navigation_style a .active-icon,
#afterLoginScreen .bottom_navigation_style a:hover .inactive-icon,
#afterLoginScreen .bottom_navigation_style a .active-icon,
#afterLoginScreen .bottom_navigation_style a:focus .inactive-icon {
  display: none;
}

#afterLoginScreen .bottom_navigation_style a .inactive-icon,
#afterLoginScreen .bottom_navigation_style a.active .active-icon,
#afterLoginScreen .bottom_navigation_style a .inactive-icon,
#afterLoginScreen .bottom_navigation_style a:hover .active-icon,
#afterLoginScreen .bottom_navigation_style a:focus .active-icon {
  display: block;
}

#afterLoginScreen .bottom_navigation_style a.active span,
#afterLoginScreen .bottom_navigation_style a:focus span,
#afterLoginScreen .bottom_navigation_style a:hover span {
  color: #032f3c;
}

.border_box_home_page {
  border: 1px solid #e4e8f1;
  background-color: #ffffff;
  border-radius: 5px;
}

.profile_user_mobile {
  display: none;
}

.profile_image_mobile {
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

.profile-image-letters {
  padding: 5px;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.profile-counseller_de-letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-size: 40px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.profile-counseller-letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 23px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.profile-qcard-letters {
  display: flex;
  justify-content: center;
  padding: 9.2px;
  width: 39px;
  height: 39px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  line-height: 1;
}

.profile-qcard-comm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8.2px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 23px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.profile_qcard_comm_txt {
  position: absolute;
  top: 0;
  left: 0;
  width: 39px;
  height: 39px;
  border-radius: 50px;
  font-size: 22px;
  padding-top: 3px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}

.profile-basic-info-letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px 12px; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-size: 48px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff !important;
  cursor: pointer;
  text-align: center;
}

.profile-menu-letters {
  /* padding: 3px 5px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff !important;
  cursor: pointer;
  text-align: center;
}

.profile-menu-letters :hover {
  padding: 8px 5px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 600;
  background-color: #032f3c;
  color: #ffffff !important;
  cursor: pointer;
  text-align: center;
}

.profile-user_coun-letters {
  display: inline-flex;
  position: relative;
  top: -32px;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 6px #ffffff solid;
  background-color: #032f3c;
  color: #ffffff;
  border-radius: 50%;
  margin: auto;
  font-size: 42px;
  text-align: center;
}

.collab_image-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #032f3c;
  color: #ffffff;
  border-radius: 50%;
  margin: auto;
  font-size: 42px;
  text-align: center;
}

.collab_image-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #032f3c;
  color: #ffffff;
  border-radius: 50%;
  margin: auto;
  font-size: 42px;
  text-align: center;
}

.profile-with-letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  background-color: #032f3c;
  color: #ffffff;
  border-radius: 19%;
  margin: auto;
  font-size: 42px;
  text-align: center;
}

#afterLoginScreen .offcanvas-collapse-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  /* right: 14px; */
  width: 244px;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  overflow-y: auto;
  visibility: hidden;
  background-color: hsl(210, 33%, 98%);
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  height: fit-content;
  border-radius: 5px;
}

#afterLoginScreen .offcanvas-collapse-mobile {
  align-items: start;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

#afterLoginScreen .offcanvas-collapse-mobile.open {
  top: 57px;
  visibility: hidden;
  background-color: hsl(210, 33%, 98%) !important;
}

.mobile_sidebar_profile {
  width: 60px !important;
  height: 60px !important;
}

.has-search {
  margin-top: 6px !important;
}

.has-search .form-control {
  background-color: #ffffff !important;
  border-radius: 25px !important;
}

.ask_edit_btn_box {
  font-size: 14px;
  font-weight: 500;
  margin-top: -10px !important;
}

.user_prfl_logo {
  margin-top: 6px !important;
}

.home_counsellor_card {
  border: 2px solid #e4e8f1;
  border-radius: 100px;
  padding: 5px 12px;
  cursor: pointer;
  display: flex;
  color: gray;
  align-items: center;
  justify-content: center;
}

.active_counsellor_mentor {
  background-color: #ffffff;
  border: 1.5px solid #002856;
  color: #000000;
}

/* ============================== main body ==================== */
.row_style_dflexing {
  display: grid;
  grid-template-columns: 6% 20% 1fr 15% 6%;
  /* grid-template-columns: 260px 860px 200px; */
  grid-gap: 10px;
  justify-content: center;
}

.left_sidebar_space {
  grid-column: 1 / 2;
  grid-row: 1;
  /* background-color: rgb(229, 238, 53);    */
}

.sidebar_final_select {
  grid-column: 2 / 3;
  grid-row: 1;
  margin-top: 90px !important;
  /* background-color: rgb(127, 227, 255) !important; */
}

.mainBoxStyle {
  grid-column: 3 / 4;
  grid-row: 1;
  /* background-color: rgb(241, 22, 124) !important; */
}

.sidebar_final_right {
  grid-column: 4 / 5;
  grid-row: 1;
  /* margin-top: 90px !important; */
  /* background-color: rgb(120, 236, 25) !important; */
}

.right_sidebar_space {
  grid-column: 5 / 6;
  grid-row: 1;
  /* background-color: rgb(229, 238, 53); */
}

/* ==================top header stlye after login ======================== */
.row_style_top_header_auth {
  display: grid;
  grid-template-columns: 6% 1fr 45% 6%;
  grid-gap: 8px;
}

.left_space_logo {
  grid-column: 1 / 2;
  grid-row: 1;
  /* background-color: rgb(62, 238, 53);    */
}

.top_header_logo_search {
  grid-column: 2 / 3;
  grid-row: 1;
  /* background-color: rgb(232, 53, 238);    */
}

.top_header_home_link {
  grid-column: 3 / 4;
  grid-row: 1;
  /* background-color: rgb(53, 176, 238);    */
}

.right_space_menu {
  grid-column: 4 / 5;
  grid-row: 1;
  /* background-color: rgb(53, 238, 72);    */
}

/* ==================top header stlye before login ======================== */
.login_signup_mobile_view_top {
  display: flex;
  align-items: center;
  justify-content: end;
  /*margin-top: 0.5rem;
  margin-bottom: 1.2rem;*/
  margin-top: 0.2rem;
    margin-bottom: 0.8rem;
}

.login_signup_mobile_view_top .login_signup_btn {
  border-radius: 5px;
  width: fit-content;
  background-color: #015b91;
  position: relative;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 5px 15px !important;
}

.before_login_navbar {
  display: grid;
  grid-template-columns: 8% 1fr 25% 8%;
  grid-gap: 8px;
  align-items: center;
  background-color: #032f3c;
}
.before_login_navbar_footer {
  display: grid;
  grid-template-columns: 8% 1fr 25% 8%;
  grid-gap: 8px;
  align-items: center;
  background-color: #141414;
}

.left_space_navbar {
  grid-column: 1 / 2;
  grid-row: 1;
}

.logo_search_navbar {
  grid-column: 2 / 3;
  grid-row: 1;
}

.login_signup_navbar {
  grid-column: 3 / 4;
  grid-row: 1;
  padding-top: 14px;
  padding-bottom: 10px;
}

.right_space_navbar {
  grid-column: 4 / 5;
  grid-row: 1;
}

.public_forum_details_style {
  display: grid;
  grid-template-columns: 8% 15% 1fr 15% 8%;
  grid-gap: 8px;
  /* align-items: center; */
}

.public_forum_left_space {
  grid-column: 1 / 2;
  grid-row: 1;
}

.public_forum_left_ads {
  grid-column: 2 / 3;
  grid-row: 1;
}

.public_forum_card_page {
  grid-column: 3 / 4;
  grid-row: 1;
}

.public_forum_right_ads {
  grid-column: 4 / 5;
  grid-row: 1;
}

.public_forum_right_space {
  grid-column: 5 / 6;
  grid-row: 1;
}

/* =================================================== */

.coming_soon_job {
  font-size: 36px;
  line-height: 54px;
  font-weight: 500;
  color: #1a6c9d;
}

.coming_soon_job .soon_style {
  font-size: 36px;
  line-height: 54px;
  font-weight: 600;
  color: #22a72f;
}

.preserved_para {
  width: 100%;
  font-family: "Poppins";
  overflow-x: auto;
  height: fit-content !important;
}

/* .adding_comment_flex_box{
    display: grid;
    grid-template-columns: 8% 1fr 10%;
    grid-gap: 5px;
}

.adding_comment_img{
    grid-column: 1 / 2;
    grid-row: 1;  
}
.adding_comment_text{
    grid-column: 2 / 3;
    grid-row: 1;
}
.adding_comment_btn{
    grid-column: 3 / 4;
    grid-row: 1;    
} */

.divtext {
  border: ridge 2px;
  padding: 8px 20px 15px 20px !important;
  width: 100% !important;
  word-break: break-all;
  cursor: text;
  /*min-height: 2.8em;*/
  overflow: auto;
  border-radius: 10px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-line {
  white-space: pre-line;
}

.inline_box_block {
  display: inline-block;
}

/* .question_card_style{
    width: 30% !important;
} */

/* ============================ new Design style =============== */
.home_card_row_flex_box {
  display: flex;
  grid-gap: 10px;
  overflow: hidden;
}

.prfl_counsellor_home_letter {
  display: inline-block;
  text-align: center;
  width: 75px;
  height: 75px;
  border-radius: 3px;
  background-color: #032f3c;
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
  padding: 5px;
}

.home_prfl_img {
  width: 75px;
  height: 75px;
  border-radius: 3px;
}

.home_cm_name {
  font-size: 14px !important;
  font-weight: 600;
}

.home_state_city {
  font-size: 10px !important;
  font-weight: 400;
  color: #000000;
}

.home_experience {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px !important;
  color: #424343;
}

.home_experience_years {
  font-weight: 700 !important;
}

.h_view_prfl,
.h_book_now {
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 2px;
  font-weight: 400;
  border: none;
}

.h_view_prfl {
  color: #000000;
  background-color: #e9e9e9;
}

.h_book_now {
  color: #ffffff;
  background-color: #032f3c;
}

.view_all_card {
  position: absolute;
  right: 15px;
  text-align: center;
  margin-top: -40px;
  margin-bottom: -60px;
  z-index: 1;
  float: right;
  cursor: pointer;
}

.view_all_card_img {
  width: 55px;
  height: 55px;
  background-color: #ffffff;
  border-radius: 50%;
}

.view_all_card_txt {
  color: #0275b1;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}

.side_head_quick {
  font-size: 18px;
  font-weight: 600;
}

.prfl_side_letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  background-color: #032f3c;
  color: #ffffff;
  border-radius: 25px;
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}

.prfl_humburger_letters {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #032f3c;
  color: #ffffff !important;
  cursor: pointer;
  text-align: center;
}

/* .border_box_home_p:last-child{
    background-image: linear-gradient(to right, #FFFFFF , rgb(181, 181, 181));
    background: linear-gradient(270deg, #F2F2F2 25.78%, rgba(242, 242, 242, 0) 133.01%);
} */

/* .grid_counseller_card{
    display: grid;
    grid-template-columns: 36% 36% 36%;
    overflow: hidden;
}

.grid_counseller_card .border_box_home_p:last-child{
    background-image: linear-gradient(to right, #FFFFFF , rgba(133, 130, 130));
    opacity: 0.2;
} */

.grid_counseller_card {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.card_box_style {
  flex: 0 0 auto;
  border: 1px solid #e4e8f1;
  background-color: #ffffff;
  border-radius: 5px;
}

.grid_counseller_card .card_box_style:last-child {
  background-image: linear-gradient(to right, #ffffff, rgba(133, 130, 130));
  opacity: 0.2;
}

/* .grid_counseller_card{
    background-color: #3af421;
} */

@media (min-width: 1300px) and (max-width: 1400px) {
  .grid_counseller_card {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 580px;
  }

  .card_box_style {
    flex: 0 0 auto;
    border: 1px solid #e4e8f1;
    background-color: #ffffff;
    border-radius: 5px;
  }

  .grid_counseller_card .card_box_style:last-child {
    background-image: linear-gradient(to right, #ffffff, rgba(133, 130, 130));
    opacity: 0.2;
  }
}

@media (min-width: 1230px) and (max-width: 1300px) {
  .grid_counseller_card {
    width: 580px;
  }
}

@media (max-width: 1250px) {
  .grid_counseller_card .card_box_style:last-child {
    display: none;
  }

  .view_all_card_img {
    width: 45px;
    height: 45px;
  }
}

.filter_btn_rounded {
  border: 1px solid #e4e8f1;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 2px 10px 5px 10px;
}

.career_path_text {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.certification_description_text {
  overflow-y: scroll;
  height: 250px;
  /* text-overflow: ellipsis; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; */
}

.certification_page_item.active .certification_page_link {
  background-color: #015c92;
  border-color: #015c92;
  color: white;
}
.certification_page_link {
  color: #015c92;
}

.page-item.active .page-link {
  background-color: #015c92;
  border-color: #015c92;
  color: white;
}
.page-link {
  color: #015c92;
}

/* ================= before login signup============ */
.modal_body_inside {
  max-height: 600px;
  overflow-y: auto;
}

.modal-content {
  box-shadow: 0 0.5rem 2rem 20px rgb(0 0 0 / 35%);
  border-radius: 10px;
}

.top_signup_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
}

.signup_who {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.signup_user_type_box {
  padding: 6px 10px;
  background-color: #fafafa;
  border-radius: 4px;
}

.signup_user_type_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #181818;
}

.signup_user_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.signup_input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background: #fafafa;
  border: 1px solid #f0f0f0 !important;
  border-radius: 5px;
  padding-left: -40px !important;
  color: #000;
  height: 40px;
}

.simple_signup_btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  background-color: #015c92;
  padding: 10px;
  color: #ffffff;
  width: 100%;
}

.google_signup_btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border: 0;
  border-radius: 5px;
  background-color: #fafafa;
  padding: 8px;
  color: #000000;
  width: 100%;
}

.already_have_account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.signup_steps {
  display: grid;
  grid-template-columns: 20px 1fr 20px 1fr 20px;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
  /* margin: 1rem auto; */
  /* background-color: aquamarine; */
}

.signup_steps_num {
  text-align: center;
  font-size: 14px;
  font-family: "Popins";
  margin-top: -20px;
}

.steps_txt {
  font-size: 10px;
}

.chech_rounded_icon {
  display: grid;
  width: 20px;
  height: 20px;
  background-color: #c5c7ca;
  /* margin-left: 12px; */
  z-index: 9999;
  border-radius: 50%;
  text-align: center;
  align-items: center;
}

.fa_check_circle {
  color: #c5c7ca;
  font-size: x-large;
  text-align: center;
  /* margin-left: 12px; */
}

.search_career_path_box {
  padding: 10px;
  border: 1px solid #e4e8f1;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
}

.input_career_path {
  border: 1px solid #e4e8f1 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #fafafa;
  height: 40px;
}

.dropdown_career_list {
  border-left: 1px solid #e4e8f1;
  border-right: 1px solid #e4e8f1;
  border-bottom: 1px solid #e4e8f1;
  height: 80px;
  overflow-y: auto;
  z-index: 1;
}

.career_list_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #fafafa;
  padding: 5px;
}

.career_list_txt_btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid #e4e8f1;
  background-color: #fafafa;
  padding: 5px 10px;
  border-radius: 25px;
  width: fit-content;
}

.spec_list_tags_btn {
  white-space: nowrap;
  display: inline-block;
  background-color: rgb(1, 92, 146, 0.15);
  font-family: "Poppins";
  color: #015c92;
  padding: 5px 14px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
}

.service_offer_add_box {
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
  grid-gap: 5px;
}

.service_offer_add_btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #f5f5f5;
  border: none;
  background-color: #015c92;
  padding: 5px 10px;
  border-radius: 5px;
}

.select_service_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid #e4e8f1;
  height: 40px;
}

.table_font_style_grid {
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #f7f7f7;
  padding: 5px 10px;
}

.table_font_style_row {
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  padding: 5px 10px;
}

/* 
#yes{
  border:2px solid white;
  box-shadow:0 0 0 1px #392;
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#yes:checked{
    box-shadow:0 0 0 1px #392;
    border:2px solid white;
    background-color:#392;
} */

.all_career_page_style {
  text-decoration: underline;
  color: #032f3c;
  cursor: pointer;
}

.added_career_grid_style {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 10px;
  align-items: start;
}

.box_img_added_career {
  grid-column: 1 / 2;
  grid-row: 1;
}

.main_tags_spec_box_style {
  grid-column: 2 / 3;
  grid-row: 1;
}

.tags_and_add_more_flex_style {
  display: grid;
  grid-template-columns: 1fr 91px;
  /* align-items: flex-end; */
  grid-gap: 20px;
}

.career_path_img_s {
  width: 69px;
  height: 69px;
  border-radius: 5px;
}

.career_title_added {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: start;
  /* margin-top: -26px; */
  right: 0;
  bottom: 0;
}

.career_spec_tags_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  gap: 3px;
  border: 2px solid #d0d0d0;
  border-radius: 20px;
  margin-bottom: -5px;
}

.career_spec_tags_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.fa_close_btn_style {
  font-size: 10px;
  cursor: pointer;
}

.added_career_count_rounded {
  /* border: 2px solid #D0D0D0;
    border-radius: 10px;
    padding: 3px 7px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  cursor: pointer;
}

.career_list_title_txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000000;
}

.career_spec_tags_show_less {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #0275b1;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 5px;
}

.forget_password_signin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #015c92;
}

.input_div_box_with_img {
  display: grid;
  grid-template-columns: 36px 1fr;
  /* grid-gap:1px; */
  align-items: center;
  border: 1px solid #e4e8f1;
  border-radius: 5px;
  background-color: #fafafa;
  padding: 2px 10px;
}

.signin_input_style {
  border: 0 !important;
  background-color: #fafafa !important;
  padding: 10px 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.add_modify_spec_tags {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  border-radius: 5px;
  background-color: #015c92;
  padding: 10px;
  color: #ffffff;
  width: 100%;
}

.inside_added_remove_career_path {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #ffffff;
  border: 2px solid #ff5a5a;
  border-radius: 5px;
  padding: 8px;
  color: #ff5f5f;
  width: 100%;
}

.page_not_found_style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.popins_fourteen_px {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.search_before__login {
  margin-top: -2px !important;
}

.line_pad4 {
  margin-bottom: 4px !important;
}

.btn_bg {
  background-color: rgba(3, 47, 60, 1);
}

.btn_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(61, 70, 174, 1);
  background-color: rgba(234, 243, 255, 1);
  height: 30px;
  width: 30px;
}
