.sort_by_filyer_shuffle
{
    font-size: 14px;
    font-weight: 400;
}

#filter_options option{
    background: #ffffff;
    color: #000;
}

.expo_txt_head
{
    font-size: 20px;
    font-weight: 600;
}

.counse_menter_exp_txt
{
    font-size: 14px;
    font-weight: 300;
  
}

.uppcomm_flex_name_view
{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.upcomming_view_all
{
    font-size: 10px;
    /* float: right; */
    cursor: pointer;
    color: rgb(2, 117, 177);
}

.upcomm_cm_user_name
{
    font-size: 14px;
    font-weight: 500;
}

/* .qcard_card_box{
    padding: 1rem 1.5rem 1rem ;
} */
.horizontal_line_qcard
{
    margin: 0.2rem -1.5rem !important;
}

.horizontal_line_qcard hr
{
    border-top: 1px solid #E4E8F1 !important;
}

.vertical_line_bt_like_comm
{
    margin: -1.5rem 0 !important;
    width: 1px !important;
    height: 58px;
    background-color: #E4E8F1;
}


.coun_men_or_card_design_flex
{
    overflow: hidden;
    height: 155px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    z-index: -1;
}

.coun_men_new_design__card
{
    color: #FAFAFA;
    border-radius: 5.20043px;
    position: relative;
    width: 100%;
    height: 155px;
    overflow: hidden;
}

.image_back_side_cm
{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 155px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.5);
}

.image_width_height
{
    width: 100% !important;
    height: 155px;
    object-fit: cover;
    border-radius: 5.20043px;
}

.inside_text_on_image__home
{
    padding: 10px;
    height: 155px;
    z-index: 10;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: end;
    color: #FFFFFF;
    border-radius: 5.20043px;
    width: 100%;
    overflow: hidden;
}

.h_coun_book_now_btn
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #000000;
    background: #FFFFFF;
    border: none;
    border-radius: 2.60021px;
    padding: 3px 6px;
    width: 72.8px;
    height: 20.2px;
}

.h_cm_career_tags_style
{
    font-weight: 500;
    font-size: 10px;
}

.home_cm_name_txt
{
    font-size: 14px !important;
    font-weight: 600;
    cursor: pointer;
}

.home_experience_txt
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: #E7E7E7;
}

.home_experience_years_txt
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF;
}

.home_cm_last_card_txt
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
}

.view_all_prfl_cm
{
    display: flex;
    width: 98.999% !important;
    color: #000000 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
}

#userprofilet
{
    /* background: linear-gradient(to right, #04619F, #191A1A); */
    height: 8rem;
    position: relative;
}

@media (min-width: 1287px)
{
    .coun_men_or_card_design_flex
    {
        overflow: hidden;
        height: 155px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
    }

    .coun_men_new_design__card
    {
        height: 155px;
        color: #FAFAFA;
        border-radius: 5.20043px;
        position: relative;
    }

    .image_back_side_cm
    {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 155px;
        width: 100%;
        border-radius: 5.200043px;
        background-color: rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.5);
    }

    .image_width_height
    {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5.20043px;
    }

    .inside_text_on_image__home
    {
        padding: 10px;
        z-index: 10;
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: end;
        color: #FFFFFF;
        border-radius: 5.20043px;
    }

    .h_cm_career_tags_style
    {
        font-weight: 500;
        font-size: 10px;
        width: 95% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .home_cm_name_txt
    {
        font-size: 14px !important;
        font-weight: 600;
        width: 95% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .home_cm_last_card_txt
    {
        width: 95% !important;
    }

}

@media (max-width: 1287px)
{
    .coun_men_or_card_design_flex
    {
        overflow: hidden;
        height: 140px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        position: relative;
    }

    .coun_men_new_design__card
    {
        height: 140px;
        width: 100%;
        overflow: hidden;
        color: #FAFAFA;
        border-radius: 5.20043px;
        position: relative;
    }

    .image_back_side_cm
    {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 140px;
        width: 100%;
        border-radius: 5.200043px;
        background-color: rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.2);
    }

    .image_width_height
    {
        height: 140px;
        object-fit: cover;
        border-radius: 5.20043px;
    }

    .inside_text_on_image__home
    {
        padding: 10px;
        height: 140px;
        z-index: 1;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: end;
        background-color: rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
        border-radius: 5.20043px;
        width: 100%;
        overflow: hidden;
    }

    .h_cm_career_tags_style
    {
        font-weight: 500;
        font-size: 10px;
        width: 95% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .home_cm_name_txt
    {
        font-size: 14px !important;
        font-weight: 600;
        width: 93% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .home_cm_last_card_txt
    {
        width: 95% !important;
    }
}


.balanace_amount_btn_side
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    background-color: #EDEDED;
    padding: 5px 10px;
    height: 36px;
    margin-bottom: 13.5px;
}

.text_balance_s
{
    font-weight: 500;
    font-size: 10px;
}

.amount_txt_val
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.addmoney_btn_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #032f3c;
    cursor: pointer;
    text-align: center;
    padding: 5px 20px;
    border-radius: 5px;
}

.certificate_btn_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF !important;
    background-color: #032f3c;
    cursor: pointer;
    text-align: center;
    padding: 10px 20px;
    border-radius: 5px;
}

.cer_card_s_grid
{
    display: grid;
    grid-template-columns: 58px 1fr;
    grid-gap: 6px;
}

.dflexing_row_cer_txt
{
    display: flex;
    flex-direction: column;

}

.cer_name_txt_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.cer_org_txt_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
}

.cer_issue_date_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
}

.cer_credential_id_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
}

.cer_tagged_skill_s
{
    background: #D9D9D9;
    border-radius: 7px;
    padding: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
}

.cer_tagged_skill_show
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    color: #032F3C;
    text-decoration: underline;
}

.show_credentials_url_btn
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #032f3c;
    padding: 5px 10px;
    border-radius: 7px;
    width: fit-content;
}

.rbt-input-multi
{
    overflow-y: scroll !important;
}

.join_now_btn
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    /* background-color: #032f3c; */
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.request_accept_btn
{
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #032F3C;
    border: 2px solid #032F3C;
    text-align: center;
}

.request_reject_btn
{
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #032F3C;
    background-color: #FFFFFF;
    border: 2px solid #032F3C;
    text-align: center;
}

.request_availability_btn
{
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #032F3C;
    text-align: center;
    cursor: pointer;
}

.request_completed_btn
{
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: rgba(33, 190, 33, 0.9);
    background-color: rgba(144, 238, 144, 50%);
    text-align: center;
}

.request_cancelled_btn
{
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(128, 8, 32, 0.674);
    background-color: rgba(243, 84, 116, 0.472);
    text-align: center;
}

.card_box_request
{
    border-radius: 10.0368px;
    border: 2px solid #E4E8F1;
    padding: 10px;
}

.d_flexing_request_card
{
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-gap: 10px;
}

.request_user_img
{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.request_prfl_ltr
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #032F3C;
    color: #FFFFFF;
    margin: auto;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.prfl_session_name
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #032F3C;
    color: #FFFFFF;
    margin: auto;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 19%;
}

.request_user_name
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.request_user_exper
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.request_user_price
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.service_title_name
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    background: #F7F7F7;
    border: 0.912437px solid #F0F0F0;
    border-radius: 45.6218px;
}

.request_time_txt
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.timeline_area
{
    position: relative;
    z-index: 1;
}

.single-timeline-area
{
    position: relative;
    top: 20px;
    z-index: 1;
    padding-left: 20px;
    /* background-color: #008C80; */
}

@media only screen and (max-width: 575px)
{
    .single-timeline-area
    {
        padding-left: 50px;
    }
}

.single-timeline-area .timeline-date
{
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 1;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media only screen and (max-width: 575px)
{
    .single-timeline-area .timeline-date
    {
        width: 100px;
    }
}

.single-timeline-area .timeline-date::after
{
    position: absolute;
    width: 2px;
    height: 110%;
    content: " ";
    background-color: rgba(0, 140, 128, 1);
    top: 10px;
    right: 30px;
    z-index: 1;
}

#yer::before
{
    position: absolute;
    list-style: none;
    width: 10px;
    height: 10px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 140, 128, 1);
    border-radius: 50%;
    background: rgba(0, 140, 128, 1);
    content: "";
    top: 40%;
    right: 26px;
    z-index: 5;
}

.single-timeline-area .timeline-date::before
{
    position: absolute;
    list-style: none;
    width: 11px;
    height: 11px;
    border: 1.5px solid #008C80;
    border-radius: 50%;
    background: #F7F9FB;
    content: "";
    top: 40%;
    right: 26px;
    z-index: 5;
}

.single-timeline-area .single-timeline-content
{
    position: relative;
    z-index: 1;
    padding: 2em 2em .62em 1em;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 20px;
}


@media only screen and (max-width: 575px)
{
    .single-timeline-area .single-timeline-content
    {
        padding: 20px;
    }
}

.single-timeline-area .single-timeline-content .timeline-icon
{
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    text-align: center;
    margin-right: 6px;
}

.single-timeline-area .single-timeline-content .timeline-icon i
{
    color: #ffffff;
    line-height: 50px;
}

.single-timeline-area .single-timeline-content .timeline-text h6
{
    -webkit-transition-duration: 500ms;
    margin-bottom: 0;
    transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p
{
    font-size: 13px;
    margin-bottom: 0;
}

#fyer
{
    position: relative;
    left: 42px;
    top: 8px;
}

#pillc
{
    border: rgba(0, 0, 0, 0.3);
}



.ct
{
    margin: 2px;
    padding: 2px;
    width: 20px;
    height: 20px;
    align-items: center;
    border-radius: 50%;
    background-color: #333;
    border: 1px solid white;

    color: #fff;
}

.bt_col
{
    background-image: linear-gradient(92.59deg, #04619F 0%, #191A1A 97.85%);
}

.bt_col2
{
    background-image: linear-gradient(99.47deg, #032F3C 0%, #0AB9ED 100%), linear-gradient(99.47deg, #84FF91 0%, #1DABFF 100%), linear-gradient(92.36deg, #0F0C29 0%, #302B63 52.4%, #24243E 100%);
}

.bt_col3
{
    background-image: linear-gradient(99.47deg, #84FF91 0%, #1DABFF 100%), linear-gradient(92.36deg, #0F0C29 0%, #302B63 52.4%, #24243E 100%);
}



.background-button
{
    color: #FFFFFFB2 !important;
    background-color: #00000080;
    font-weight: 400;
    font-size: 12px;
}

.t_hr
{
    border: 1px solid #000000;
    opacity: 0.15;
}

.collaboration_tags_s
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

/* .counsellor_overview_s{
    margin-top: -26px;
} */

.counsellor_prfl_switch_links
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6) !important;
}

.activ_uprfl_coun
{
    color: #3D46AE !important;
    border-bottom: 2px solid #3D46AE;
    font-weight: 500;
}


.selected_per_list
{
    background: #FDFBF9;
}

.count_review_nm_s
{
    background: rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    padding: 0px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
}

.count_active_review_nm_s
{
    background: #3D46AE;
    border-radius: 25px;
    padding: 0px 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
}

.service_book_now_btn
{
    /* width: 137px;
height: 34px; */
    padding: 2px 6px;
    background: #032F3C;
    border-radius: 4px;
}

.service_book_now_txt
{
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
}

.text_desc_service
{
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}


.mentor_enter_s
{
    border: 1px solid black !important;
    padding: 0.375rem 0.755rem;
    color: #000;
    border-radius: 5px;
    width: 100%;
}

.text_head_time_line{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
}

.user_prfl_title_new{
    font-size: 18px !important;
    font-weight: 500 !important;
}

.dflex_comm_send_box{
    display: grid;
    grid-template-columns: 50px 1fr 75px;
    align-items: flex-start;
    grid-gap:10px;
}

/* .dflex_comm_send_box:last-child {
    align-items: flex-end;
} */


.share_popup_style_forum{
    border: 1px solid #E4E8F1;
    padding: 10px;
    border-radius: 5px;
    margin-left: -80px;
    margin-top: 10px;
    background-color: #FFFFFF;
    z-index: 1;
}
.share_flex_style_forum{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
@media only screen and (max-width: 1155px) {
  .x-factor-date-responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .x-factor-date{
    margin-bottom: 10px;
  }
 
}

.grid_couns_mentor_card_style{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: 5px;
    padding: 2px;
}
.grid_couns_mentor_card_style::-webkit-scrollbar {
    width: 2em;
}
/* .grid_couns_mentor_card_style::-webkit-scrollbar {
    display: none;
  } */
  
  /* .grid_couns_mentor_card_style {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  } */

.couns_menter_chip{
    background: rgba(0, 0, 0, 0.04);
    border-radius: 48.9121px;
    color: #000000;
    padding: 8px 12px;
    display: inline;
    cursor: pointer;
}

.couns_mentor_chip_txt{
    display: inline-flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
}