.onboard_flex_row_style{
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap:10px;
}

.onboard_date_time_style{
    font-weight: 500;
    font-size: 14px;
    color: #A5ACB8;
}

.mark_as_read_dot_style{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    background-color: #808080;
}

.mark_as_read_dot_style_blue{
   
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #3D46AE;
   
}

.mark_as_read_dot_style_green{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #46ec62;
   
}

.complete_noti_profile{
    color: #015C92;
    cursor: pointer;
    border-bottom: 1px solid #015C92;
}

.accept_session_request{
    border: 2px solid #015C92;
    border-radius: 5px;
    background-color: #015C92;
    padding: 2px 10px;
    color: #FFFFFF;
    cursor: pointer;
}
.reject_session_request{
    border: 2px solid #A5ACB8;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 2px 10px;
    cursor: pointer;
}