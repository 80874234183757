.review-popup-header
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #000000;
}

.review-popup-header > h5 > p
{
	color: #47A567;
}

.review-rating
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.6);
}

.review-description,
.review-title
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.6);
}



.review-post-btn-container
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 10px 10px;
	height: 45px;
	background: #032F3C;
	border-radius: 5px;

}

.modal-header
{
	border-bottom: 0 none;
}

.modal-footer
{

	border-top: 0 none;
}