





/* ========== start medium screen between 1024 to 1250 ======== */

@media (min-width: 1024px) and (max-width: 1250px){

    .row_style_dflexing{
        grid-template-columns: 6% 21% 1fr 15% 6%;
    }

    .left_sidebar_space{
        grid-column: 1 / 2;
        grid-row: 1;
        /* background-color: rgb(229, 238, 53);    */
    }
    
    .sidebar_final_select{
        grid-column: 2 / 3;
        grid-row: 1;
        margin-top: 90px !important;
        /* background-color: rgb(127, 227, 255) !important; */
    }
    .mainBoxStyle{
        grid-column: 3 / 4;
        grid-row: 1;
        /* background-color: rgb(241, 84, 22) !important; */
    }
    .sidebar_final_right{
        grid-column: 4 / 5;
        grid-row: 1;
        margin-top: 90px !important;
        /* background-color: rgb(120, 236, 25) !important; */
    }
    .right_sidebar_space{
        grid-column: 5 / 6;
        grid-row: 1;
        /* background-color: rgb(229, 238, 53); */
        
    }

    /* .nav_links_manage_request{
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    } */
    

    #afterLoginScreen .article-body {
        padding: 0rem 1rem 0rem 1rem;
        margin-top: 5.2rem;
    }

    #afterLoginScreen .admin-sidebar.sideBarAbso .fixedAfterScroll {
        position: fixed;
        top: 85px; 
        width: 21%;
      }
    .profile_user_mobile{
        display: none !important;
    }

    /* styling */
    .qcard_head{
        font-size: 1rem;
    }
    .qcard_title_question{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
    .qcard_date{
        font-size: 10px;
    }
    .qcard_read{
        font-size: 12px;
    }
    
    .text_color_bold{
        color: rgb(6, 6, 6) !important;
        font-weight: 400 !important;
    }
    
    .text_size_bold{
        font-weight: 900 !important;
    }
    .text_para{
        font-size: 14px !important;
    }
    .text_para1{
        font-size: 13px !important;
    }
    .text_para2{
        font-size: 12px !important;
    }
    .text_para3{
        font-size: 15px !important;
    }
    .text_para_sign{
        font-size: 18px !important;
        margin-bottom: 0;
    }

    .coming-image-job{
        width: 100%;
    }

}

/* ========== end medium screen between 1024 to 1250 ======== */


/* ========== start medium screen between 991 to 1024 ======== */
@media (min-width: 991px) and (max-width: 1024px){

    
    .row_style_dflexing{
        display: grid;
        grid-template-columns: 6% 20% 1fr 3%;
        grid-gap:10px;
    }

    .left_sidebar_space{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    
    .sidebar_final_select{
        grid-column: 2 / 3;
        grid-row: 1;
        margin-top: 90px !important;
    }
    .mainBoxStyle{
        grid-column: 3 / 4;
        grid-row: 1;
    }
    .right_sidebar_space{
        grid-column: 4 / 5;
        grid-row: 1;
        /* background-color: aqua; */
    }

    .sidebar_final_right, .profile_user_mobile{
        display: none !important;
    }

    #afterLoginScreen .article-body {
        padding: 0rem 1rem 0rem 1rem;
        margin-top: 5.2rem;
    }

    .has-search{
        margin-top: 6px !important;
    }
    #afterLoginScreen .admin-sidebar.sideBarAbso .fixedAfterScroll {
        position: fixed;
        top: 85px; 
        width: 21%;
    }

    /* styling */
    .qcard_head{
        font-size: 1rem;
    }
    .qcard_title_question{
        font-weight: 500 !important;
    }
    .qcard_date{
        font-size: 10px;
    }
    .qcard_read{
        font-size: 12px;
    }

    .text_color_bold{
        color: rgb(6, 6, 6) !important;
        font-weight: 400 !important;
    }
    
    .text_size_bold{
        font-weight: 900 !important;
    }
    .text_para{
        font-size: 14px !important;
    }
    .text_para1{
        font-size: 13px !important;
    }
    .text_para2{
        font-size: 12px !important;
    }
    .text_para3{
        font-size: 15px !important;
    }
    .text_para_sign{
        font-size: 18px !important;
        margin-bottom: 0;
    }

    .coming-image-job{
        width: 100%;
    }
}

/* ========== End medium screen between 991 to 1024 ======== */