#social-header{
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
}
.socialPopup-container .modal-dialog{
	max-width: 505px;
	height: 248px;
	
}
.social-modalBody {
	margin: 15px;
	
}
.socialpopup-close{
	font-weight: 600;
	width: 1.5em;
	height: 1.5em;
	text-align: center;
	background-color: #EEEEEE;
	border-radius: 50%;
	cursor: pointer;
	margin-bottom: 12px;
}
.social-option-tag{
	display: flex;
flex-direction: row;
align-items: flex-start;
padding: 3px 16px;
gap: 10px;
isolation: isolate;

/*width: 441px;*/
width: 100%;
height: 32px;

background: #EEEEEE;
border-radius: 7px;
border: none;
}
.social-text{
	/*width: "100%";*/
	 border-radius:  "10px";
	  background-color: "#032F3C";
	width: 72px;
	height: 18px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #6F6F6F;
}
#select-social-channel{
	margin-bottom: 5px;
}
.url-section{
	margin-top: 29px;
}
#link-url{
	width: 114px;
height: 21px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
color: #000000;
}

.link-input-box{
	display: flex;
flex-direction: row;
align-items: flex-start;
padding: 7px 16px;
gap: 10px;

width: 100%;
height: 32px;

background: #EEEEEE;
border-radius: 7px;
border: none;
 border-radius: "10px";
  background-color: "#032F3C";

}