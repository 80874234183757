.step_item__list {
  @apply relative flex flex-col justify-center items-center w-36;
}
.step_item__list:not(:first-child):before {
  @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/4 -translate-y-2/4;
}
.step_list__txt {
  @apply w-10 h-10 flex items-center justify-center z-10 relative bg-slate-300 rounded-full font-semibold text-white;
}
.active_step_list .step_list__txt {
  @apply bg-green-500;
}
.complete_step_list .step_list__txt {
  @apply bg-green-600;
}
.complete_step_list p {
  @apply text-green-800;
}
.complete_step_list:not(:first-child):before,
.active_step_list:not(:first-child):before {
  @apply bg-green-600;
}

.step_list__txt{
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: -5px;
  color: #FFFFFF;
}
