#circle-name-img
{
	width: 116.08px;
	height: 113px;
	border: 6px solid #FFFFFF;
	/*margin-top: 1px;*/
}

#user-basic-detail-container
{
	/*min-width: 754px;*/
	min-height: 112px;
	background: #FFFFFF;
	border-radius: 0px;
}

.at-the-rate-detail
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.7);
}

.at-the-rate-detail > img
{

	margin-left: 3px;
	margin-right: 3px;
}

.user-state
{
	/*width: 104px;*/
	height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.7);

}

#userprofile
{
	height: 149px !important
}

.user-name
{
	/*min-width: 164px;*/
	min-height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
}

.eye-btn
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 9.08772px 5.19298px;
	gap: 12.98px;

	width: 29.43px;
	height: 31.16px;

	background: #EAF3FF;
	border-radius: 3.89474px;
}

.eye-btn > img
{
	width: 13.2px;
	height: 9px
}

.upload-btn > img
{
	width: 13.2px;
	height: 9px;

}

.upload-btn > button
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 9.08772px 5.19298px;
	gap: 12.98px;
	width: 29.43px;
	height: 31.16px;
	background: #EAF3FF;
	border-radius: 3.89474px;

}

.counsellor_prfl_switch_links
{
	font-weight: 500;
}


.collab-btn-div
{
	display: flex;
	flex-direction: row;
}

.collab-btn
{

	padding: 6px 16px;
	/*width: 128px;*/
	height: 32px;
	background: #032F3C;
	border-radius: 1000px;
	border-radius: 1000px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #FFFFFF;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: none;
}

.collab-btn > img
{
	margin-right: 0.3vmax;
}