




/* ========== Start small screen between 876 to 991 ======== */
@media (min-width: 876px) and (max-width: 991px){

    
    .row_style_dflexing{
        display: grid;
        grid-template-columns: 6% 23% 1fr 3%;
        grid-gap:10px;
    }

    .left_sidebar_space{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    
    .sidebar_final_select{
        grid-column: 2 / 3;
        grid-row: 1;
        margin-top: 90px !important;
    }
    .mainBoxStyle{
        grid-column: 3 / 4;
        grid-row: 1;
    }
    .right_sidebar_space{
        grid-column: 4 / 5;
        grid-row: 1;
    }

    .sidebar_final_right{
        display: none;
    }

    #afterLoginScreen .article-body {
        margin-top: 5.5rem;
        padding: 0rem 1rem 0rem 1rem;
    }

    .has-search{
        margin-top: 6px !important;
    }
    #afterLoginScreen .admin-sidebar.sideBarAbso .fixedAfterScroll {
        position: fixed;
        top: 85px; 
        width: 23%;
    }
    

    .qcard_head{
        font-size: 1rem;
    }
    .qcard_title_question{
        font-weight: 500 !important;
    }
    .qcard_date{
        font-size: 10px;
    }
    .qcard_read{
        font-size: 12px;
    }
    .text_color_bold{
        color: rgb(6, 6, 6) !important;
        font-weight: 400 !important;
    }
    
    .text_size_bold{
        font-weight: 900 !important;
    }
    .text_para{
        font-size: 14px !important;
    }
    .text_para1{
        font-size: 13px !important;
    }
    .text_para2{
        font-size: 12px !important;
    }
    .text_para3{
        font-size: 15px !important;
    }
    .text_para_sign{
        font-size: 18px !important;
        margin-bottom: 0;
    }
}

/* ========== End small screen between 876 to 991 ======== */


/* ========== Start small screen between 425 to 876 ======== */
@media (min-width:425px) and (max-width: 876px){

    
    .row_style_dflexing{
        display: grid;
        grid-template-columns: 6% 1fr  6%;
        grid-gap:10px;
    }

    .left_sidebar_space{
        grid-column: 1 / 2;
        grid-row: 1;
        /* background-color: rgb(229, 238, 53);    */
    }

    .mainBoxStyle{
        grid-column: 2 / 3;
        grid-row: 1;
        margin-top: 60px !important;
        /* background-color: rgb(241, 84, 22) !important; */
    }
    .right_sidebar_space{
        grid-column: 3 / 4;
        grid-row: 1;
        /* background-color: rgb(22, 241, 117) !important; */
    }

    .sidebar_final_right,
    .sidebar_final_select{
        display: none;
    }

    #afterLoginScreen .article-body {
        padding: 0 !important;
        /* padding: 0rem 1rem 1rem; */
    }

    .has-search{
        margin-top: 6px !important;
    }

    .qcard_head{
        font-size: 1rem;
    }
    .qcard_title_question{
        font-weight: 500 !important;
    }
    .qcard_date{
        font-size: 10px;
    }
    .qcard_read{
        font-size: 12px;
    }

    .text_color_bold{
        color: rgb(6, 6, 6) !important;
        font-weight: 400 !important;
    }
    
    .text_size_bold{
        font-weight: 900 !important;
    }
    .text_para{
        font-size: 14px !important;
    }
    .text_para1{
        font-size: 13px !important;
    }
    .text_para2{
        font-size: 12px !important;
    }
    .text_para3{
        font-size: 15px !important;
    }
    .text_para_sign{
        font-size: 18px !important;
        margin-bottom: 0;
    }
    
    .coming-image-job{
        width: 100%;
    }
    /* .profile_user_mobile{
        display: block !important;
        padding-right: 6px !important;
        cursor: pointer;
    } */
}


/* ========= */

/* ========== Start =================*/
@media (min-width:425px) and (max-width: 991px){
    .saphal_logo_top{
        /* width: 55px; */
        height: 30px;
    }

    .row_style_top_header_auth{
        grid-template-columns: 6%  1fr 8% 6%;
    }
    
    
    .left_space_logo{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    .top_header_logo_search{
        grid-column: 2 / 3;
        grid-row: 1;
    }
    .top_header_home_link{
        grid-column: 3 / 4;
        grid-row: 1;
        /* background-color: rgb(53, 238, 78);  */
    }
    .right_space_menu{
        grid-column: 4 / 5;
        grid-row: 1;  
    }
    

    .login_signup_mobile_view_top{
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .page-search {
        display: none !important;
    }

    /* .mobile_saphal_logo{
        width: 65px;
        height: 30px;
    } */
    .login_signup_mobile_view_top .login_signup_btn{
        border-radius: 5px;
        width: fit-content;
        background-color: #015b91;
        position: relative;
        font-size: 18px !important;
        padding: 5px 15px !important;
    }
    .before_login_navbar{
        display: grid;
        grid-template-columns: 1%  1fr 55% 1%;
        grid-gap:8px;
        align-items: center;
        padding: 10px ;
    }
    
    .left_space_navbar{
        grid-column: 1 / 2;
        grid-row: 1;  
    }
    .logo_search_navbar{
        grid-column: 2 / 3;
        grid-row: 1;
    }
    .login_signup_navbar{
        grid-column: 3 / 4;
        grid-row: 1;
          
    }
    .right_space_navbar{
        grid-column: 4 / 5;
        grid-row: 1; 
    }

    .coming-image-job{
        width: 100%;
    }
      
}
/* ========== End =================*/

