.nav_links_manage_request {
  display: flex;
  overflow-x: auto;
  max-width: 650px;
}

.nav_links_manage_request span {
  white-space: nowrap;
  padding: 0 8px;
}

.nav_links_manage_request span span {
  cursor: pointer;
  
}

/* Hide the scrollbar */
.nav_links_manage_request::-webkit-scrollbar {
  display: none;
}
